import { Filter } from './filter.model';
import * as FilterAction from './filter.action';

export interface FilterState {
    readonly filter: Filter;
}

const initialState: Filter = {
    filters: [],
};

export function filterReducer(state: Filter, action: FilterAction.FilterActions) {

    switch (action.type) {

        case FilterAction.GET_FILTER:
            return action.payload;

        case FilterAction.CLEAR_FILTERS:
            return initialState;

        default: return state;
    }
}
