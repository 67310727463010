import { Component, OnInit, ViewChild, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { CommunicationService } from 'src/app/shared/services/communication.service';
import { CookieTrailService } from 'src/app/shared/services/cookie-trail.service';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { FilterState } from 'src/app/shared/stores/filter/filter.reducer';
import { UserState } from 'src/app/shared/stores/user/user.reducer';
import * as FilterAction from '../../shared/stores/filter/filter.action';
import { environment } from 'src/environments/environment';
declare var $: any;
export interface PeriodicElement {
  name: string;
  totalProfiles: number;
  totalDownload: number;
  available: number;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {name: 'Hydrogen', totalProfiles: 1000, totalDownload: 395, available: 33},
  {name: 'Helium', totalProfiles: 2040, totalDownload: 395, available: 33},
  {name: 'Lithium', totalProfiles: 2040, totalDownload: 395, available: 33},
  {name: 'Beryllium', totalProfiles: 2040, totalDownload: 395, available: 33},
  {name: 'Boron', totalProfiles: 2040, totalDownload: 395, available: 33},
  {name: 'Carbon', totalProfiles: 2040, totalDownload: 395, available: 33},
  {name: 'Nitrogen', totalProfiles: 2040, totalDownload: 395, available: 33},
  {name: 'Oxygen', totalProfiles: 2040, totalDownload: 395, available: 33},
  {name: 'Fluorine', totalProfiles: 2040, totalDownload: 395, available: 33},
  {name: 'Neon', totalProfiles: 2040, totalDownload: 395, available: 33},
];

@Component({
  selector: 'app-profile-type',
  templateUrl: './profile-type.component.html',
  styleUrls: ['./profile-type.component.scss']
})
export class ProfileTypeComponent implements OnInit {

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  socket;
  formatter;
  customerId;
  features;
  dashboardData;
  profileSpinner = true;
  profileTypes;

  sampleData = [
    {AVAILABLE: 44, profileType: "PrivateGroup01", total: 50, DOWNLOADED: 80},
    {AVAILABLE: 57, profileType: "PrivateGroup02", total: 150, DOWNLOADED: 29},
    {AVAILABLE: 24, profileType: "PrivateGroup03", total: 40, DOWNLOADED: 29},
    {AVAILABLE: 35, profileType: "PrivateGroup04", total: 46, DOWNLOADED: 20},
    {AVAILABLE: 294, profileType: "PrivateGroup05", total: 78, DOWNLOADED: 30},
    {AVAILABLE: 2, profileType: "PrivateGroup06", total: 57, DOWNLOADED: 20},
    {AVAILABLE: 39, profileType: "PrivateGroup07", total: 98, DOWNLOADED: 3},
  ];

  @Output() getProfileType = new EventEmitter<string>();

  fieldList = [
    { name: 'Allocated', selected: false },
    { name: 'Linked', selected: false },
    { name: 'Confirmed', selected: false },
    { name: 'Enabled', selected: false },
    { name: 'Error', selected: false },
    { name: 'Reusable', selected: false },
    { name: 'Unavailable', selected: false },
    { name: 'Disabled', selected: false },
  ];
  activeTableColumns = [];
  dynamicStyle =  {
    width: [
      { name: 'Allocated', width: '0.4fr' },
      { name: 'Linked', width: '0.4fr' },
      { name: 'Confirmed', width: '0.5fr' },
      { name: 'Enabled', width: '0.4fr' },
      { name: 'Error', width: '0.3fr' },
      { name: 'Reusable', width: '0.3fr' },
      { name: 'Unavailable', width: '0.3fr' },
      { name: 'Disabled', width: '0.3fr' }
    ]
  };
  currentCustomer;
  rootCustomer;
  manualRefresh = false;
  isDashboardUser = false;
  whiteLabelEnable = environment.whiteLabelEnable;

  constructor(private cookieTrailService: CookieTrailService,
    private appComponent: AppComponent,
    private userStore: Store<UserState>,
    private communicationService: CommunicationService,
    private dashboardService: DashboardService,
    private translate: TranslateService,
    private router: Router,
    private filterStore: Store<FilterState>,) { }

  ngOnInit(): void {
    this.cookieTrailService.saveCookie({});
    this.socket = this.appComponent.socket;

    this.formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,      
      maximumFractionDigits: 0,
    });

    localStorage.setItem('cookieTrail', JSON.stringify({}));
    
    this.userStore.select('user')
    .subscribe((user: any) => {
      if(user) {
        this.features = user.featureRole[user.featureRole.findIndex(ele => ele.name === 'Dashboard')].features;
        this.getInventory();
        this.getInventoryWidget();

        if(user.data.currentCustomer) {
          this.customerId = user.data.currentCustomer._id;
        } else {
          this.customerId = user.data.rootCustomer._id;
        }

        this.rootCustomer = user.data.rootCustomer;
        this.currentCustomer = user.data.currentCustomer;

        if(user.data.roleNames.includes('dashboard-user') && user.data.roleType === 'default') {
          this.isDashboardUser = true;
          this.setWidgetInvterval();
        }
      }
    });

    this.communicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
      });
    
    this.communicationService.getRefreshDashboardAction()
      .subscribe((res: any) => {
        this.getInventory();
        this.getInventoryWidget();
      });

    this.communicationService.getDashboardInventory()
      .subscribe((res: any) => {
        this.fieldList = [
          { name: 'Allocated', selected: false },
          { name: 'Linked', selected: false },
          { name: 'Confirmed', selected: false },
          { name: 'Enabled', selected: false },
          { name: 'Error', selected: false },
          { name: 'Reusable', selected: false },
          { name: 'Unavailable', selected: false },
          { name: 'Disabled', selected: false },
        ];
        this.updateTableColums('');
      });  
  }

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  syncProfileType() {
    this.profileSpinner = true;
    this.dashboardService.syncProfileType()
      .subscribe((res: any) => {
        const alertType = res.success ? 'success' : 'error';
        this.communicationService.openAlert({ alertType, alertMsg: res.message });
        if (res.success) {
          this.getInventory();
          this.getInventoryWidget();
        } else {
          this.profileSpinner = false;
        }
      })
  }

  setWidgetInvterval() {
    this.dashboardService.getWidgetRefreshTime()
      .subscribe((res: any) => {
        
        if (res.refreshTimeSetting.length > 0) {
          let index = res.refreshTimeSetting.findIndex(ele => ele.name === 'Profile Inventory');
          if(res.refreshTimeSetting[index].time != 'manual') { 
            setInterval(() => {
              this.getInventory();
              this.getInventoryWidget();
            }, Number(res.refreshTimeSetting[index].time));
            this.manualRefresh = false;
          } else {
            this.manualRefresh = true;
          }
        } else {
          setInterval(() => {
            this.getInventory();
            this.getInventoryWidget();
          }, 60000 * 1)
        }
      });
  }

  changeLanguage() { 

  }

  getInventory() {
    this.profileSpinner = true;
    this.dashboardService.getInventory()
      .subscribe((res: any) => {
        this.profileTypes = res;
        this.profileSpinner = false;
      }, err => {
        console.log(err);
        this.profileSpinner = false;
      });
  }

  calcHeightInventory(){
    const gridsterInventoryHeight = $("#Profile\\ Inventory").height();
    return `${gridsterInventoryHeight - 104}px`;
  }

  getInventoryWidget() {
    this.dashboardService.getInventoryWidget().subscribe((result) => {
      if (result['data'].length > 0) {
        // modified data since old list might have Final property
        this.fieldList = this.fieldList.map(field => {
          return {
            ...field,
            selected: result['data'].find(d => d.name === field.name)?.selected || false
          }
        })
        this.activeTableColumns = this.fieldList.map(d => {
          if (d.selected) {
              return d.name;
            }
        });
        this.activeTableColumns = this.activeTableColumns.filter(i => i);
      } else {
        this.activeTableColumns = [];
      }
    });
  }

  getFeatureIndex(keyName) {
    return this.features ? this.features.findIndex(ele => ele.key === keyName) : '';
  }

  navigateToManageProfile(profileObj, type) {
    if((this.currentCustomer && !this.currentCustomer.isParent)|| (!this.currentCustomer && this.rootCustomer && !this.rootCustomer.isParent)) {
      profileObj.type = 'group';
      this.filterStore.dispatch(new FilterAction.GetFilter(Object.assign([], profileObj)));
    } else if((this.currentCustomer && this.currentCustomer.isParent) || (!this.currentCustomer && this.rootCustomer && this.rootCustomer.isParent)) {
      profileObj.type = type;
      this.filterStore.dispatch(new FilterAction.GetFilter(Object.assign([], profileObj)));
    }
    this.router.navigate(profileObj.groupQR ? ['/manage-group-profile/profile-inventory'] : ['/profile']);
  }

  sendProfileTypeData(value: string) {
    this.getProfileType.emit(value);
  }

  updateTableColums(item) {
    if(item) {
      item.selected = !item.selected;
    }
    this.dashboardService.setInventoryWidget(this.fieldList).subscribe((result) => {
      this.fieldList = result['data'];
      this.activeTableColumns = this.fieldList.map(d => {
        if(d.selected) {
            return d.name;
          }
      });
      this.activeTableColumns = this.activeTableColumns.filter(i => i);
    });
  } 

  getStyles() {
    let selected = this.fieldList.filter(element => element.selected).length;
      let str = '190px 0.5fr 0.4fr 0.4fr 0.4fr 0.5fr 0.4fr ' 
      this.dynamicStyle.width.forEach(st => {
        if(this.activeTableColumns.includes(st.name)) {
          str += selected <=7 ? `0.4fr ` : `${st.width} `
        }
      });
      str += '165px';
      return {
        'grid-template-columns': str
      }
  }

  showList(e) {
    e.stopPropagation();
  }

  refresh() {
    this.getInventory();
    this.getInventoryWidget();
  }
}
