import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomRedirectGuard implements CanActivate {
    constructor(private router: Router) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const condition = this.checkCondition();
        if (condition) {
            this.router.navigateByUrl('/customer');
            return false;
        }
        return true;
    }
    private checkCondition(): boolean {
        const isPageNotFound = /dashboard/.test(window?.location?.pathname);
        return isPageNotFound;
    }
}