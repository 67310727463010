import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { MustMatch } from '../../shared/validators/must-match.validators';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from 'src/app/shared/services/communication.service';
import { environment } from 'src/environments/environment';
import { ColorLuminanceService } from '../../shared/services/color-luminance.service';
declare var $: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPassForm: FormGroup;
  whiteLabelEnable = environment.whiteLabelEnable;
  whiteLabelLogo = environment.whiteLabelLogo;
  whiteLabeSigninTheme = environment.whiteLabeSigninTheme;
  submitted = false;
  err = false;
  key;
  langSelected = 'en';
  load = 'default';
  loginScreenObj;
  logoUrl;
  singleSubdomain;
  subdomain = false;
  apiResponse = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private colorLuminanceService: ColorLuminanceService) { }

  ngOnInit() {

    this.getLoginScreenTheme();

    const hostname = window.location.host;
    //smartjac.my.10ttech.com/ 
    if(hostname.includes('smartjac')){
      this.load = 'smartjac'
    }

    this.route.queryParams.subscribe((params: any) => {
      this.key = params.token;
      if (this.key) {
      }
    });

    if(localStorage.getItem('language')) {
      this.langSelected = localStorage.getItem('language')
    } else {
      this.langSelected = 'en';
    }

    this.resetPassForm = this.formBuilder.group({
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  get f() { return this.resetPassForm.controls; }

  checkLinkValidation(key) {
    this.authService.linkValidate(key)
      .subscribe((res: any) => {
      }, err => {
        this.router.navigate(['/forgot-password']);
      });
  }

  onResetPass() {
    this.submitted = true;
    if (this.resetPassForm.invalid) {
      return;
    }

    const passwordData = {
      newPassword: this.resetPassForm.get('password').value,
      verifyPassword: this.resetPassForm.get('confirmPassword').value,
    };
    this.authService.reset(this.key, passwordData);
  }

  changeLanguage() {
    localStorage.setItem('language', this.langSelected);
    this.translate.use(this.langSelected);
  }

  getLoginScreenTheme() {
    console.log(window.location.host);
    const data = {
      subdomainURL: window.location.host
    };
    this.apiResponse = false;

    this.authService.getLoginScreenTheme(data)
      .subscribe((res: any) => {
        this.loginScreenObj = res;
        this.apiResponse = true;

        if (this.loginScreenObj.length > 0 || this.whiteLabelEnable) {

          this.subdomain = true;
          
          setTimeout(() => {
            $('.createaccount-form').css('padding', '140px 50px');
            $('.signin-inner').css('border', '1px solid rgb(116, 114, 114)');
            $('.signin-inner').css('border-radius', '15px');
            $('.onboarding-form-input').css('border-radius', '0.25rem');
            $('.onboarding-form-input').css('background-color', 'transparent');
            $('.signin-inner-btn').css('border-radius', '0.25rem');
            $('.signin-inner-cancel-btn').css('border-radius', '0.25rem');
          }, 10);
          
          this.singleSubdomain = this.loginScreenObj[0];

          if (this.whiteLabelEnable) {
            this.logoUrl = `https://s3.amazonaws.com/subdomain-logos.10ttech.com/${this.whiteLabelLogo}`;
            this.singleSubdomain = this.whiteLabeSigninTheme;
            this.loginScreenObj = [0]; // this to remove the trance
          } else {
            this.logoUrl = this.singleSubdomain.logoUrl;
          }

          document.documentElement.style.setProperty('--p-main-login-background', this.singleSubdomain.background);
          document.documentElement.style.setProperty('--p-main-login-leftSide', this.singleSubdomain.leftColor);
          document.documentElement.style.setProperty('--p-main-login-rightSide', this.singleSubdomain.rightColor);
          document.documentElement.style.setProperty('--p-main-login-btn', this.singleSubdomain.button);
          document.documentElement.style.setProperty('--p-main-login-btn-text', this.singleSubdomain.buttonTextColor);
          document.documentElement.style.setProperty('--p-main-login-cancel-btn',  this.singleSubdomain.cancelButton);
          document.documentElement.style.setProperty('--p-main-login-cancel-btn-text',  this.singleSubdomain.cancelButtonTextColor);
          document.documentElement.style.setProperty('--p-main-login-font', this.singleSubdomain.font);
          document.documentElement.style.setProperty(
            '--invalid-input-font-color',
            this.getVisibleErrorFontColor(this.colorLuminanceService.getColorLuminance(this.singleSubdomain.rightColor))
          );
        } else {

          this.subdomain = false;
          this.logoUrl = 'https://s3.amazonaws.com/subdomain-logos.10ttech.com/image-10t.png';

          // document.documentElement.style.setProperty('--p-main-login-background', 'black');
          // document.documentElement.style.setProperty('--p-main-login-leftSide', '');
          // document.documentElement.style.setProperty('--p-main-login-rightSide', '#00bc90');
          // document.documentElement.style.setProperty('--p-main-login-btn', '#000');
          // document.documentElement.style.setProperty('--p-main-login-btn-text', '#fff');
          document.documentElement.style.setProperty('--p-main-login-cancel-btn', '#004533');
          document.documentElement.style.setProperty('--p-main-login-cancel-btn-text', '#ffffff');
          // document.documentElement.style.setProperty('--p-main-login-font', 'rgb(54, 54, 54)');

          document.documentElement.style.setProperty('--p-main-login-background', '#fff');
          document.documentElement.style.setProperty('--p-main-login-leftSide', '');
          document.documentElement.style.setProperty('--p-main-login-rightSide', '#fff');
          document.documentElement.style.setProperty('--p-main-login-btn', '#000');
          document.documentElement.style.setProperty('--p-main-login-btn-text', '#fff');
          document.documentElement.style.setProperty('--p-main-login-font', 'rgb(54, 54, 54)');
          document.documentElement.style.setProperty(
            '--invalid-input-font-color', 
            this.getVisibleErrorFontColor(this.colorLuminanceService.getColorLuminance('#fff'))
          );

          setTimeout(() => {
            $('.carousel').carousel({
              interval: 4000
            });
            $('.carousel-control.right').trigger('click');
          }, 10);
        }
      }, err => {
        this.apiResponse = true;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  getVisibleErrorFontColor(luminance) {
    if (luminance > 0.5) {
      return '#FF0000';
    }
    return '#DFFF00';
  }
}
