<div class="inner-main-profile" style="overflow: auto; max-height: calc(100vh - 90px);">
    <div class="result-row">
        <div class="result-table">
            <div class="face-container-inner-bg">
                <div class="profile-manage-row result-not-found-row" style="min-height: 60px;">
                    <h4 class="mb-0 result-not-found-row-heading sg-font-regular">{{'Customer Management' | translate}}</h4>
                    <div class="ml-auto" *ngIf="!isRoot && multitenant || whiteLabelEnable">
                        <button class="btn t-bgcolor outline sg-font-regular" data-toggle="modal" data-target="#createCustomer" (click)="editOn=false;getUnAssignedGroups('');customerName='';selectedGroups=[];" [disabled]="disableCreate"> <i class="fa fa-plus mr-2" aria-hidden="true"></i>{{!customerId || !isRoot ? 'Add New Tenant': 'Add New Customer' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <div class="customer-manage-table">
                        <div class="t-grid-table-new">
                            <div class="t-head-bg" [ngClass]="{'t-grid-status-table-wrapper-without-group': !customerId || !isRoot, 't-grid-status-table-wrapper': customerId}" [style.grid-template-columns]="getGridWrapper()">
                                <div class="t-box a">{{'Customer Name' | translate}}</div>
                                <div class="t-box a">{{'Total Users' | translate}}</div>
                                <div class="t-box a" *ngIf="customerId && !isRoot">{{'Group' | translate}}</div>
                                <!-- <div class="t-box b" *ngIf="whiteLabelEnable && isRoot">{{'Prod MNO ID' | translate}}</div>
                                <div class="t-box b" *ngIf="whiteLabelEnable && isRoot">{{'PreProd MNO ID' | translate}}</div> -->
                                <ng-container *ngFor="let env of environments">
                                    <div class="t-box b" *ngIf="whiteLabelEnable && isRoot">
                                        {{ getHeader(env) | translate }}
                                    </div>
                                </ng-container>
                                <div class="t-box b">{{'Action' | translate}}</div>
                            </div>
                            <div class="t-profile-status-overflow accordion sg-font-regular" id="custMgmt" style="overflow-x: hidden;" [ngStyle]="{'overflow-y': (!customerList || (customerList && customerList.length <= 0)) ? 'unset' : 'scroll' }">
                                <!--  -->
                                <div class="col-lg-12 show-notification-overflow" *ngIf="customerListSpinner">
                                    <div class="row d-block py-3 p-2">
                                        <div class="loader-flex-center">
                                            <div class="loader quantum-spinner m-auto"></div>
                                        </div>
                                    </div>
                                </div>
                                <!-- customerListSpinner -->
                                <div *ngIf="customerList && customerList.length <= 0 && !customerListSpinner">
                                    <div class="row border-bottom-light d-block py-3 p-2">
                                        <div>
                                            <div class="text-center">{{'No Customers Found' | translate}}.</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- customerListSpinner -->
                                <div *ngIf="customerList && customerList.length > 0 && !customerListSpinner">
                                    <ng-container *ngFor="let customer of customerList; let i = index">
                                        <div class="t-body-bg border-bt-ext-light" [ngClass]="{'t-grid-status-table-wrapper-without-group': !customerId || !isRoot, 't-grid-status-table-wrapper': customerId}" [style.grid-template-columns]="getGridWrapper()">
                                            <div class="t-box d t-font-light cursor-p customer-list" style="padding-left: 25px !important;" data-toggle="collapse" [attr.data-target]="'#custMgmt_' + i+'_1'" aria-expanded="false" [ngClass]="{'cutomer-list-arrow collapsed': customer.child && customer.child.length > 0}">
                                                <a [attr.id]="'/customer-selection/' + customer._id" routerLinkActive="active" (click)="gotoCustomer(customer._id, ['/customer', customer._id]);getChildCustomers(customer._id);" >{{isProd ? customer.prodName : customer.name}}</a>
                                                <!--  -->
                                            </div>
                                            <div class="t-box d t-font-light">
                                                {{customer.totalUser ? customer.totalUser : 0}}
                                            </div>
                                            <div class="t-box d t-font-light" *ngIf="customerId && !isRoot">
                                                <span *ngIf="customer.groups && customer.groups.length === 0">NA</span>
                                                <span *ngIf="customer.groups && customer.groups.length > 0" class="d-flex">
                                                    <span *ngFor="let group of customer.groups; let i = index;" style="white-space: nowrap;">
                                                        {{group.groupName}}<span *ngIf="customer.groups.length !== i+1">, </span>
                                                    </span>
                                                </span>
                                            </div>
                                            <!-- <div class="t-box d t-font-light" *ngIf="whiteLabelEnable && isRoot">
                                                {{getMnoIdentifier(customer, 'PROD')}}
                                            </div>
                                            <div class="t-box d t-font-light" *ngIf="whiteLabelEnable && isRoot">
                                                {{getMnoIdentifier(customer, 'PRE-PROD')}}
                                            </div> -->
                                            <ng-container *ngFor="let env of environments">
                                                <div class="t-box d t-font-light" *ngIf="whiteLabelEnable && isRoot">
                                                    {{getMnoIdentifier(customer, env)}}
                                                </div>
                                            </ng-container>

                                            <div class="t-box d t-font-light profile-manage-icon-row">
                                                <i class="fa fa-pencil-square-o t-color cursor-p" aria-hidden="true" data-toggle="modal" data-target="#createCustomer" (click)="getSingleCustomer(i); editOn = true;getUnAssignedGroups(customer.parent);updateEnvModel(this.customerList[i]);"></i>
                                                <i class="fa fa-trash t-color cursor-p" data-toggle="modal" data-target="#deleteCustomerModal" aria-hidden="true" *ngIf="customerId" (click)="deleteCustomerId=customer._id; deleteCustomerName = isProd ? customer.prodName : customer.name"></i>
                                            </div>
                                        </div>
                                        <div class="collapse" id="custMgmt_{{i}}_1" [attr.data-parent]="'#custMgmt'" aria-labelledby="headingOne" *ngIf="!isRoot">
                                            <div class="">
                                                <ng-container *ngFor="let child1 of customer.child; index as j">
                                                    <div class="t-body-bg border-bt-ext-light" [ngClass]="{'t-grid-status-table-wrapper-without-group': !customerId, 't-grid-status-table-wrapper': customerId}">
                                                        <div class="t-box d t-font-light customer-list-accordion accordion_padding_j custom-background cursor-p customer-list" data-toggle="collapse" [attr.data-target]="'#custMgmt_' + i+j" aria-expanded="false" [ngClass]="{'cutomer-list-arrow collapsed': child1.child && child1.child.length > 0}">
                                                            <a [attr.id]="'/customer-selection/' + child1._id" 
                                                            routerLinkActive="active" (click)="gotoCustomer(child1._id, ['/customer', customer._id, child1._id]);getChildCustomers(child1._id);">{{isProd ? child1.prodName : child1.name}}</a>
                                                            <!--  -->
                                                            <!--  -->
                                                        </div>
                                                        <div class="t-box d t-font-light custom-background">
                                                            {{child1.totalUser ? child1.totalUser : 0}}
                                                        </div>
                                                        <div class="t-box d t-font-light custom-background" *ngIf="customerId">
                                                            <span *ngIf="child1.groups && child1.groups.length === 0">NA</span>
                                                            <span *ngIf="child1.groups && child1.groups.length > 0">
                                                                <span *ngFor="let group of child1.groups; let i = index;">
                                                                    {{group.groupName}}<span *ngIf="child1.groups.length !== i+1">, </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div class="t-box d t-font-light profile-manage-icon-row custom-background">
                                                            <i class="fa fa-pencil-square-o t-color cursor-p" aria-hidden="true" data-toggle="modal" data-target="#createCustomer" (click)="getSingleCustomer(i,j); editOn = true;getUnAssignedGroups(child1.parent);"></i>
                                                            <i class="fa fa-trash t-color cursor-p" data-toggle="modal" data-target="#deleteCustomerModal" aria-hidden="true" *ngIf="customerId" (click)="deleteCustomerId=child1._id; deleteCustomerName = isProd ? child1.prodName : child1.name"></i>
                                                        </div>
                                                    </div>
                                                    <div class="collapse" id="custMgmt_{{i}}{{j}}" [attr.data-parent]="'#custMgmt_' + i+'_1'" aria-labelledby="headingOne" >
                                                        <ng-container *ngFor="let child2 of child1.child; index as k">
                                                            <div class="t-body-bg border-bt-ext-light" [ngClass]="{'t-grid-status-table-wrapper-without-group': !customerId, 't-grid-status-table-wrapper': customerId}">
                                                                <div class="t-box d t-font-light customer-list-accordion accordion_padding_k custom-background cursor-p customer-list" data-toggle="collapse" [attr.data-target]="'#custMgmt_' + i+j+k" aria-expanded="false" [ngClass]="{'cutomer-list-arrow collapsed': child2.child && child2.child.length > 0}">
                                                                    <!--  -->
                                                                    <!--  -->
                                                                    <a  [attr.id]="'/customer-selection/' + child2._id" routerLinkActive="active" (click)="gotoCustomer(child2._id, ['/customer', customer._id, child1._id, child2._id]);getChildCustomers(child2._id);">{{isProd ? child2.prodName : child2.name}}</a>
                                                                </div>
                                                                <div class="t-box d t-font-light custom-background">
                                                                    {{child2.totalUser ? child2.totalUser : 0}}
                                                                </div>
                                                                <div class="t-box d t-font-light custom-background" *ngIf="customerId">
                                                                    <span *ngIf="child2.groups && child2.groups.length === 0">NA</span>
                                                                    <span *ngIf="child2.groups && child2.groups.length > 0">
                                                                        <span *ngFor="let group of child2.groups; let i = index;">
                                                                            {{group.groupName}}<span *ngIf="child2.groups.length !== i+1">, </span>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div class="t-box d t-font-light profile-manage-icon-row custom-background">
                                                                    <i class="fa fa-pencil-square-o t-color cursor-p" aria-hidden="true" data-toggle="modal" data-target="#createCustomer" (click)="getSingleCustomer(i,j,k); editOn = true;getUnAssignedGroups(child2.parent);"></i>
                                                                    <i class="fa fa-trash t-color cursor-p" data-toggle="modal" data-target="#deleteCustomerModal" aria-hidden="true" *ngIf="customerId" (click)="deleteCustomerId=child2._id; deleteCustomerName = isProd ? child2.prodName : child2.name"></i>
                                                                </div>
                                                            </div>
                                                            <div class="collapse" id="custMgmt_{{i}}{{j}}{{k}}" [attr.data-parent]="'#custMgmt_' + i+j" aria-labelledby="headingOne" >
                                                                <ng-container *ngFor="let child3 of child2.child; index as l">
                                                                    <div class="t-body-bg border-bt-ext-light" [ngClass]="{'t-grid-status-table-wrapper-without-group': !customerId, 't-grid-status-table-wrapper': customerId}">
                                                                        <div class="t-box d t-font-light customer-list-accordion accordion_padding_l custom-background cursor-p customer-list" data-toggle="collapse" [attr.data-target]="'#custMgmt_' + i+j+k+l" aria-expanded="false" [ngClass]="{'cutomer-list-arrow collapsed': child3.child && child3.child.length > 0}">
                                                                            <!--   -->
                                                                            <!--  -->
                                                                            <a [attr.id]="'/customer-selection/' + child3._id" routerLinkActive="active" (click)="gotoCustomer(child3._id, ['/customer', customer._id, child1._id, child2._id, child3._id]);getChildCustomers(child3._id);">{{isProd ? child3.prodName : child3.name}}</a>
                                                                        </div>
                                                                        <div class="t-box d t-font-light custom-background">
                                                                            {{child3.totalUser ? child3.totalUser : 0}}
                                                                        </div>
                                                                        <div class="t-box d t-font-light custom-background" *ngIf="customerId">
                                                                            <span *ngIf="child3.groups && child3.groups.length === 0">NA</span>
                                                                            <span *ngIf="child3.groups && child3.groups.length > 0">
                                                                                <span *ngFor="let group of child3.groups; let i = index;">
                                                                                    {{group.groupName}}<span *ngIf="child3.groups.length !== i+1">, </span>
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                        <div class="t-box d t-font-light profile-manage-icon-row custom-background">
                                                                            <i class="fa fa-pencil-square-o t-color cursor-p" aria-hidden="true" data-toggle="modal" data-target="#createCustomer" (click)="getSingleCustomer(i,j,k,l); editOn = true;getUnAssignedGroups(child3.parent);"></i>
                                                                            <i class="fa fa-trash t-color cursor-p" data-toggle="modal" data-target="#deleteCustomerModal" aria-hidden="true" *ngIf="customerId" (click)="deleteCustomerId=child3._id; deleteCustomerName = isProd ? child3.prodName : child3.name"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div class="collapse" id="custMgmt_{{i}}{{j}}{{k}}{{l}}" [attr.data-parent]="'#custMgmt_' + i+j+k" aria-labelledby="headingOne" >
                                                                        <ng-container *ngFor="let child4 of child3.child; index as m">
                                                                            <div class="t-body-bg border-bt-ext-light" [ngClass]="{'t-grid-status-table-wrapper-without-group': !customerId, 't-grid-status-table-wrapper': customerId}">
                                                                                <div class="t-box d t-font-light customer-list-accordion accordion_padding_m custom-background cursor-p customer-list" data-toggle="collapse" [attr.data-target]="'#custMgmt_' + i+j+k+l+m" aria-expanded="false" [ngClass]="{'cutomer-list-arrow collapsed': child4.child && child4.child.length > 0}">
                                                                                                                                                                                                        <!--  -->
                                                                                    <!--  -->
                                                                                    <a [attr.id]="'/customer-selection/' + child4._id" routerLinkActive="active" (click)="gotoCustomer(child4._id, ['/customer', customer._id, child1._id, child2._id, child3._id, child4._id]);getChildCustomers(child4._id);">{{isProd ? child4.prodName : child4.name}}</a>
                                                                                </div>
                                                                                <div class="t-box d t-font-light custom-background">
                                                                                    {{child4.totalUser ? child4.totalUser : 0}}
                                                                                </div>
                                                                                <div class="t-box d t-font-light custom-background" *ngIf="customerId">
                                                                                    <span *ngIf="child4.groups && child4.groups.length === 0">NA</span>
                                                                                    <span *ngIf="child4.groups && child4.groups.length > 0">
                                                                                        <span *ngFor="let group of child4.groups; let i = index;">
                                                                                            {{group.groupName}}<span *ngIf="child4.groups.length !== i+1">, </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </div>
                                                                                <div class="t-box d t-font-light profile-manage-icon-row custom-background">
                                                                                    <i class="fa fa-pencil-square-o t-color cursor-p" aria-hidden="true" data-toggle="modal" data-target="#createCustomer" (click)="getSingleCustomer(i,j,k,l,m); editOn = true;getUnAssignedGroups(child4.parent);"></i>
                                                                                    <i class="fa fa-trash t-color cursor-p" data-toggle="modal" data-target="#deleteCustomerModal" aria-hidden="true" *ngIf="customerId" (click)="deleteCustomerId=child4._id;deleteCustomerName = isProd ? child4.prodName : child4.name"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div class="collapse" id="custMgmt_{{i}}{{j}}{{k}}{{l}}{{m}}" [attr.data-parent]="'#custMgmt_' + i+j+k+l" aria-labelledby="headingOne" >
                                                                                <ng-container *ngFor="let child5 of child4.child; index as n">
                                                                                    <div class="t-body-bg border-bt-ext-light" [ngClass]="{'t-grid-status-table-wrapper-without-group': !customerId, 't-grid-status-table-wrapper': customerId}">
                                                                                        <div class="t-box d t-font-light customer-list-accordion accordion_padding_n custom-background cursor-p customer-list" data-toggle="collapse" [attr.data-target]="'#custMgmt_' + i+j+k+l+m+n" aria-expanded="false" [ngClass]="{'cutomer-list-arrow collapsed': child5.child && child5.child.length > 0}">
                                                                                            <!--  -->
                                                                                            <!-- gotoCustomer() -->
                                                                                            <a [attr.id]="'/customer-selection/' + child5._id" routerLinkActive="active" (click)="gotoCustomer(child5._id, ['/customer', customer._id, child1._id, child2._id, child3._id, child4._id, child5._id]); getChildCustomers(child5._id);">{{isProd ? child5.prodName : child5.name}}</a>
                                                                                        </div>
                                                                                        <div class="t-box d t-font-light custom-background">
                                                                                            {{child5.totalUser ? child5.totalUser : 0}}
                                                                                        </div>
                                                                                        <div class="t-box d t-font-light custom-background" *ngIf="customerId">
                                                                                            <span *ngIf="child5.groups && child5.groups.length === 0">NA</span>
                                                                                            <span *ngIf="child5.groups && child5.groups.length > 0">
                                                                                                <span *ngFor="let group of child5.groups; let i = index;">
                                                                                                    {{group.groupName}}<span *ngIf="child5.groups.length !== i+1">, </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="t-box d t-font-light profile-manage-icon-row custom-background">
                                                                                            <i class="fa fa-pencil-square-o t-color cursor-p" aria-hidden="true" data-toggle="modal" data-target="#createCustomer" (click)="getSingleCustomer(i,j,k,l,m,n); editOn = true;getUnAssignedGroups(child5.parent);"></i>
                                                                                            <i class="fa fa-trash t-color cursor-p" data-toggle="modal" data-target="#deleteCustomerModal" aria-hidden="true" *ngIf="customerId" (click)="deleteCustomerId=child5._id;deleteCustomerName = isProd ? child5.prodName : child5.name"></i>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="collapse" id="custMgmt_{{i}}{{j}}{{k}}{{l}}{{m}}{{n}}" [attr.data-parent]="'#custMgmt_' + i+j+k+l+m" aria-labelledby="headingOne" >
                                                                                        <ng-container *ngFor="let child6 of child5.child; index as o">
                                                                                            <div class="t-body-bg border-bt-ext-light" [ngClass]="{'t-grid-status-table-wrapper-without-group': !customerId, 't-grid-status-table-wrapper': customerId}">
                                                                                                <div class="t-box d t-font-light customer-list-accordion accordion_padding_o custom-background cursor-p customer-list">
                                                                                                    <!--   -->
                                                                                            <!-- gotoCustomer() -->
                                                                                                    <a [attr.id]="'/customer-selection/' + child6._id" routerLinkActive="active" (click)="gotoCustomer(child6._id, ['/customer', customer._id, child1._id, child2._id, child3._id, child4._id, child5._id, child6._id]); getChildCustomers(child6._id)">{{isProd ? child6.prodName : child6.name}}</a>
                                                                                                </div>
                                                                                                <div class="t-box d t-font-light custom-background">
                                                                                                    {{child6.totalUser ? child6.totalUser : 0}}
                                                                                                </div>
                                                                                                <div class="t-box d t-font-light custom-background" *ngIf="customerId">
                                                                                                    <span *ngIf="child6.groups && child6.groups.length === 0">NA</span>
                                                                                                    <span *ngIf="child6.groups && child6.groups.length > 0">
                                                                                                        <span *ngFor="let group of child6.groups; let i = index;">
                                                                                                            {{group.groupName}}<span *ngIf="child6.groups.length !== i+1">, </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div class="t-box d t-font-light profile-manage-icon-row custom-background">
                                                                                                    <i class="fa fa-pencil-square-o t-color cursor-p" aria-hidden="true" data-toggle="modal" data-target="#createCustomer" (click)="getSingleCustomer(i,j,k,l,m,n,o); editOn = true;getUnAssignedGroups(child6.parent);"></i>
                                                                                                    <i class="fa fa-trash t-color cursor-p" data-toggle="modal" data-target="#deleteCustomerModal" aria-hidden="true" *ngIf="customerId" (click)="deleteCustomerId=child6._id; deleteCustomerName = isProd ? child6.prodName : child6.name"></i>
                                                                                                </div>
                                                                                            </div>
                                                                                        </ng-container>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </div>
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </div> 
                                        </div>
                                    </ng-container>
                                </div>
                                
                            </div>

                            <!-- <div class="t-profile-status-overflow">
                                <div *ngFor="let customer of customerList; index as i">
                                    <div class="t-body-bg border-bt-ext-light" [ngClass]="{'t-grid-status-table-wrapper-without-group': !customerId, 't-grid-status-table-wrapper': customerId}">
                                        <div class="t-box d t-font-light ">
                                            <span class="cursor-p" (click)="getChildCustomers(customer._id)" data-toggle="collapse" [attr.data-target]=" '#customerMgmtCollapseList_' + i + '_1'" href="#multiCollapseListExample1" aria-controls="multiCollapseListExample1">{{isProd ? customer.prodName : customer.name}}</span>
                                        </div>
                                        <div class="t-box d t-font-light">
                                            {{customer.userCount}}
                                        </div>
                                        <div class="t-box d t-font-light" *ngIf="customerId">
                                            <span *ngIf="customer.groups && customer.groups.length === 0">NA</span>
                                            <span *ngIf="customer.groups && customer.groups.length > 0">
                                                <span *ngFor="let group of customer.groups; let i = index;">
                                                    {{group.groupName}}<span *ngIf="customer.groups.length !== i+1">, </span>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="t-box d t-font-light profile-manage-icon-row">
                                            <i class="fa fa-pencil-square-o t-color cursor-p" aria-hidden="true" data-toggle="modal" data-target="#createCustomer" (click)="getSingleCustomer(i); editOn = true;getUnAssignedGroups(i);"></i>
                                            <i class="fa fa-trash t-color cursor-p" data-toggle="modal" data-target="#deleteCustomerModal" aria-hidden="true" *ngIf="customerId" (click)="selectedIndex=i;"></i>
                                        </div>
                                    </div>

                                    <div id="customerMgmtCollapseList_{{i}}_1" class="collapse" aria-labelledby="headingOne" [attr.data-parent]="'#customerMgmtCollapse'">
                                        <div *ngFor="let child of customer.child; index as j">
                                            <div class="t-body-bg border-bt-ext-light" [ngClass]="{'t-grid-status-table-wrapper-without-group': !customerId, 't-grid-status-table-wrapper': customerId}">
                                                <div class="t-box d t-font-light ">
                                                    <span class="cursor-p" data-toggle="collapse" [attr.data-target]=" '#customerMgmtCollapseList_' + i+j" href="#multiCollapseListExample1" aria-controls="multiCollapseListExample1">{{isProd ? child.prodName : child.name}}</span>
                                                    
                                                    ***** gotoCustomer(customer._id);   ****
                                                </div>
                                                <div class="t-box d t-font-light">
                                                    {{child.userCount}}
                                                </div>
                                                <div class="t-box d t-font-light" *ngIf="customerId">
                                                    <span *ngIf="child.groups && child.groups.length === 0">NA</span>
                                                    <span *ngIf="child.groups && child.groups.length > 0">
                                                        <span *ngFor="let group of child.groups; let i = index;">
                                                            {{group.groupName}}<span *ngIf="child.groups.length !== i+1">, </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="t-box d t-font-light profile-manage-icon-row">
                                                    <i class="fa fa-pencil-square-o t-color cursor-p" aria-hidden="true" data-toggle="modal" data-target="#createCustomer" (click)="getSingleCustomer(i); editOn = true;getUnAssignedGroups(i);"></i>
                                                    <i class="fa fa-trash t-color cursor-p" data-toggle="modal" data-target="#deleteCustomerModal" aria-hidden="true" *ngIf="customerId" (click)="selectedIndex=i;"></i>
                                                </div>
                                            </div>
                                        </div>
                                        ***** <div *ngFor="let child1 of child.child; index as k">
                                            <div class=""></div>
                                            <div *ngFor="let child2 of child1.child; index as l">
                                                <div class=""></div>
                                                <div *ngFor="let child3 of child2.child; index as m">
                                                    <div class=""></div>
                                                    <div *ngFor="let child4 of child3.child; index as n">
                                                        <div class=""></div>
                                                        <div *ngFor="let child5 of child4.child; index as o">
                                                            <div class=""></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> *******
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="t-profile-status-overflow">
                                <ng-container *ngFor="let customer of customerList; let i = index">
                                    <div class="t-body-bg border-bt-ext-light" [ngClass]="{'t-grid-status-table-wrapper-without-group': !customerId, 't-grid-status-table-wrapper': customerId}">
                                        <div class="t-box d t-font-light ">
                                            <span class="cursor-p" (click)="getChildCustomers(customer._id)" data-toggle="collapse" href="#multiCollapseExample1" aria-controls="multiCollapseExample1">{{isProd ? customer.prodName : customer.name}}</span>
                                            ********* gotoCustomer(customer._id); ***********
                                        </div>
                                        <div class="t-box d t-font-light">
                                            {{customer.userCount}}
                                        </div>
                                        <div class="t-box d t-font-light" *ngIf="customerId">
                                            <span *ngIf="customer.groups && customer.groups.length === 0">NA</span>
                                            <span *ngIf="customer.groups && customer.groups.length > 0">
                                                <span *ngFor="let group of customer.groups; let i = index;">
                                                    {{group.groupName}}<span *ngIf="customer.groups.length !== i+1">, </span>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="t-box d t-font-light profile-manage-icon-row">
                                            <i class="fa fa-pencil-square-o t-color cursor-p" aria-hidden="true" data-toggle="modal" data-target="#createCustomer" (click)="getSingleCustomer(i); editOn = true;getUnAssignedGroups(i);"></i>
                                            <i class="fa fa-trash t-color cursor-p" data-toggle="modal" data-target="#deleteCustomerModal" aria-hidden="true" *ngIf="customerId" (click)="selectedIndex=i;"></i>
                                        </div>
                                    </div>
                                    <div id="multiCollapseExample1" class="collapse" aria-labelledby="headingOneA" data-parent="#customerAccordian">
                                        <div class="py-0 d-flex">
                                            <div class="t-box d t-font-light ">
                                                <span class="cursor-p" (click)="getChildCustomers(customer._id)" data-toggle="collapse" href="#multiCollapseExample1" aria-controls="multiCollapseExample1">{{isProd ? customer.prodName : customer.name}}</span>
                                            </div>
                                            <div class="t-box d t-font-light">
                                                {{customer.userCount}}
                                            </div>
                                            <div class="t-box d t-font-light" *ngIf="customerId">
                                                <span *ngIf="customer.groups && customer.groups.length === 0">NA</span>
                                                <span *ngIf="customer.groups && customer.groups.length > 0">
                                                    <span *ngFor="let group of customer.groups; let i = index;">
                                                        {{group.groupName}}<span *ngIf="customer.groups.length !== i+1">, </span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="t-box d t-font-light profile-manage-icon-row">
                                                <i class="fa fa-pencil-square-o t-color cursor-p" aria-hidden="true" data-toggle="modal" data-target="#createCustomer" (click)="getSingleCustomer(i); editOn = true;getUnAssignedGroups(i);"></i>
                                                <i class="fa fa-trash t-color cursor-p" data-toggle="modal" data-target="#deleteCustomerModal" aria-hidden="true" *ngIf="customerId" (click)="selectedIndex=i;"></i>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createCustomer" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title sg-font-regular" id="exampleModalLabel">{{editOn ? (getModalHeader("Edit") | translate) : (getModalHeader("Add New") | translate)}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="initEnvModel();">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4 device-flex-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group text-left mb-4">
                            <label class="t-label sg-font-small-regular" for="cName">{{'Customer Name' | translate}}</label>
                            <input type="text" class="form-control t-input sg-font-regular" id="cName" [(ngModel)]="customerName" (keyup)="customerNameErr=false;" (keypress)="isSpace($event)" aria-describedby="emailHelp">
                            <p class="small text-danger mt-2 mb-0" *ngIf="customerNameErr">{{"Please enter customer name" | translate}}!</p>
                        </div>
                    </div>
                    <div class="col-md-12 mb-4" *ngIf="customerId && show && !isRoot">
                        <label class="t-label pull-left mr-2 sg-font-small-regular" for="profile-type-select ">{{'Select Group' | translate}}: </label>
                        <ng-multiselect-dropdown class="sg-font-small-regular" id="profile-type-select" placeholder="{{'Search group here' | translate}}..." [settings]="groupDropdownSettings" [data]="groupList" [(ngModel)]="selectedGroups" (ngModelChange)="selectGroups()">
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="col-md-12" *ngIf="customerId && !isRoot">
                        <div class="selected-profile-types ml-2 mb-2 d-flex" *ngIf="selectedGroups && selectedGroups.length > 0">
                            <div class="selected-profile-types-selected-btns" *ngFor="let group of selectedGroups; index as i">
                                <span>{{group.groupName ? group.groupName : group}}</span>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngFor="let env of envModels">
                        <div class="col-md-12" *ngIf="whiteLabelEnable && isRoot">
                            <div class="form-group text-left mb-4">
                                <label class="t-label sg-font-small-regular">{{getHeader(env.env) | translate}}</label>
                                <input type="text" class="form-control t-input sg-font-regular" [(ngModel)]="env.customerMnoId" (keyup)="env.customerMnoIdErr=false;" (keypress)="isSpace($event)" aria-describedby="emailHelp">
                                <p class="small text-danger mt-2 mb-0" *ngIf="env.customerMnoIdErr">{{getMnoIdErrorMessage(env.env) | translate}}!</p>
                            </div>
                        </div>
                    </ng-container>
                    <!-- <div class="col-md-12" *ngIf="whiteLabelEnable && isRoot">
                        <div class="form-group text-left mb-4">
                            <label class="t-label sg-font-small-regular" for="cMnoId">{{'Prod MNO ID' | translate}}</label>
                            <input type="text" class="form-control t-input sg-font-regular" id="cMnoId" [(ngModel)]="customerMnoId" (keyup)="customerMnoIdErr=false;" (keypress)="isSpace($event)" aria-describedby="emailHelp">
                            <p class="small text-danger mt-2 mb-0" *ngIf="customerMnoIdErr">{{"Please enter customer Prod MNO ID" | translate}}!</p>
                        </div>
                    </div>
                    <div class="col-md-12" *ngIf="whiteLabelEnable && isRoot">
                        <div class="form-group text-left mb-4">
                            <label class="t-label sg-font-small-regular" for="cPreProdMnoId">{{'PreProd MNO ID' | translate}}</label>
                            <input type="text" class="form-control t-input sg-font-regular" id="cPreProdMnoId" [(ngModel)]="customerPreProdMnoId" (keyup)="customerPreProdMnoIdErr=false;" (keypress)="isSpace($event)" aria-describedby="emailHelp">
                            <p class="small text-danger mt-2 mb-0" *ngIf="customerPreProdMnoIdErr">{{"Please enter customer PreProd MNO ID" | translate}}!</p>
                        </div>
                    </div> -->
                </div>
                <button class="btn t-bgcolor btn-block my-3 outline sg-font-regular" (click)="editOn ? editCustomer() : createCustomer();" *ngIf="!createCustomerSpinner">{{"Submit" | translate}}</button>
                <div class="btn-spinner" *ngIf="createCustomerSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteCustomerModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title sg-font-regular" id="exampleModalLabel">{{'Delete Customer Confirmation' | translate}}</h5>
                <button type="button" class="close t-color outline" data-dismiss="modal" id="closeDeleteCustomer" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center p-4">
                <!-- <p>{{'Are you sure you want to delete' | translate}} '{{isProd && customerList && customerList[selectedIndex] ? ' ' + customerList[selectedIndex].prodName : (!isProd && customerList && customerList[selectedIndex] ? ' ' + customerList[selectedIndex].name : '')}}'</p> -->
                <p>{{'Are you sure you want to delete' | translate}} '{{deleteCustomerName}}'</p>
                <button class="btn t-bgcolor btn-block my-3 outline sg-font-regular" *ngIf="!deleteSpinner" (click)="deleteCustomer()"> {{'Confirm' | translate}}</button>
                <div class="btn-spinner" *ngIf="deleteSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
            </div>
        </div>
    </div>
</div>
