import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { DashboardModel } from '../models/dashboard-config';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  serverUrl = localStorage.getItem('env') ? localStorage.getItem('env') : environment.serverUrl;
  private readonly dynamicDashboardData = 'assets/db/dashboard.json'

  constructor(private http: HttpClient) { }

  getHeader() {
    const authToken = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      })
    };
    return httpOptions;
  }

  getDashboardData() {
    return this.http.get(`${this.serverUrl}/dashboard`, this.getHeader())
      .pipe(map(response => response));
  }

  getGroupQR() {
    return this.http.get(`${this.serverUrl}/dashboard/activeQr`, this.getHeader())
      .pipe(map(response => response));
  }

  getClientIpAddress() {
    return this.http.get(`http://api.ipify.org/?format=json`)
      .pipe(map(response => response));
  }

  getTotalDownload() {
    return this.http.post(`${this.serverUrl}/dashboard/download/card`, {} ,this.getHeader())
      .pipe(map(response => response));
  }

  getTotalDownloadGraphData(data) {
    return this.http.post(`${this.serverUrl}/dashboard/download/graph`, data ,this.getHeader())
      .pipe(map(response => response));
  }

  getDeviceAnalytics(data) {
    return this.http.post(`${this.serverUrl}/dashboard/device-analytics`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getDeviceAnalyticsDetails(data) {
    return this.http.post(`${this.serverUrl}/dashboard/device-analytics/details`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getDynamicDashboardData(): Observable<DashboardModel>{
    return this.http.get<DashboardModel>(`${this.serverUrl}/dashboard/widgets`, this.getHeader())
      .pipe(map(response => response)); 
  }

  getStaticDashboardData(): Observable<DashboardModel> {
    return this.http.get<DashboardModel>("assets/db/dashboard.json").pipe(map(response => response)); ;
  }

  updateDashboard(data): Observable<DashboardModel> {
		// const httpOptions = {
		// 	headers: new HttpHeaders({
		// 	  'Content-Type':  'application/json'
		// 	})
		// };
    return this.http.post<DashboardModel>(`${this.serverUrl}/dashboard/widgets`, data ,this.getHeader())
      .pipe(map(response => response)); 
		// return this.http.post<DashboardModel>(`http://localhost:3000/dashboards/${id}`, params, httpOptions);
	}

  getForecastGraph() {
    return this.http.post(`${this.serverUrl}/dashboard/download/forecast/graph`, {}, this.getHeader())
      .pipe(map(response => response));
  }

  getProfileTypeLimit(data) {
    return this.http.post(`${this.serverUrl}/profile-type-limit/get`, data, this.getHeader())
      .pipe(map(response => response));
  }

  setProfileLimit(data) {
    return this.http.post(`${this.serverUrl}/profile-type-limit`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getInventory() {
    return this.http.get(`${this.serverUrl}/dashboard/inventory`, this.getHeader())
      .pipe(map(response => response));
  }

  setInventoryWidget(data) {
    return this.http.post(`${this.serverUrl}/user/dynamicInventory`, data, this.getHeader())
      .pipe(map(response => response));
  }

  getInventoryWidget() {
    return this.http.get(`${this.serverUrl}/user/dynamicInventory`, this.getHeader())
    .pipe(map(response => response));
  }

  getWidgetRefreshTime() {
    return this.http.get(`${this.serverUrl}/dashboard/widgetRefreshTime`, this.getHeader())
      .pipe(map(response => response));
  }

  setWidgetRefreshTime(data) {
    return this.http.post(`${this.serverUrl}/dashboard/widgetRefreshTime`, data, this.getHeader())
      .pipe(map(response => response));
  }

  syncProfileType() {
    return this.http.post(`${this.serverUrl}/sync-profile-type`, {}, this.getHeader())
      .pipe(map(response => response));
  }
}
