import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { UserState } from '../../stores/user/user.reducer';
import * as UserAction from '../../stores/user/user.action';
import { Store } from '@ngrx/store';
import { CommunicationService } from '../../services/communication.service';
import { AppComponent } from 'src/app/app.component';
import { NotificationService } from '../../services/notification.service';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CookieTrailService } from '../../services/cookie-trail.service';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  platformName = environment.platformName;
  whiteLabelEnable = environment.whiteLabelEnable;
  helpDesk = environment.whiteLabelEnable ? environment.whiteLabelHelpDesk : 'https://helpdesk.10ttech.com/';
  role;
  routePermission;
  socket;
  notificationList = [];
  badgeShow = false;
  isprod = true;
  user;
  toggleDisable = false;
  customerName;
  langSelected;
  features;
  defaultRoute = '/dashboard';
  rootCustomer;
  currentCustomer;
  roleNameString = '';
  roleNameTooltip = '';
  roleNameTooltipCount = 0;
  displayName;
  firstTimeLogin;
  languageList = environment.languageList;
  selectedLanguage;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private userStore: Store<UserState>,
    private communicationService: CommunicationService,
    private appComponent: AppComponent,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private cookieTrailService: CookieTrailService,
    private router: Router,) {

      this.translate.addLangs(environment.languageArray);
      this.translate.setDefaultLang('en');
      // const browserLang = this.translate.getBrowserLang();
      let browserLang = localStorage.getItem('language');
      if (!browserLang) {
        browserLang = 'en';
        localStorage.setItem('language', browserLang);
      }
      this.langSelected = browserLang.match(environment.langRegex) ? browserLang : 'en';
      this.selectedLanguage = this.languageList[this.languageList.findIndex(ele => ele.key === this.langSelected)].name;
      this.translate.use(this.langSelected);
    }

  ngOnInit() {
    this.socket = this.appComponent.socket;

    $(document).ready(() => {
      $('[data-toggle="tooltip"]').tooltip();
    });

    // $('#welcomeMessage').on('hidden.bs.modal', (() => {
    //   this.firstTimeLoginUser();
    // }));

    this.userService.getUser()
      .subscribe((res: any) => {
        this.user = res;
        this.displayName = this.user.data.firstName + ' ' +this.user.data.lastName;
        for(let i = 0; i < this.user.data.roleNames.length; i++) {
          if(this.roleNameString.length <= 40) {
            this.roleNameString = i + 1 === this.user.data.roleNames.length ? this.roleNameString + this.user.data.roleNames[i]
                                              : this.roleNameString + this.user.data.roleNames[i] + ', ';
          }

          if(this.roleNameString.length > 35) {
            this.roleNameTooltip = i + 1 === this.user.data.roleNames.length ? this.roleNameTooltip + this.user.data.roleNames[i].toString()
                                              : this.roleNameTooltip + this.user.data.roleNames[i].toString() + ',';
            this.roleNameTooltipCount++;
          }
        }

        this.role = res.data.roles[0];
        this.routePermission = res.permissions;
        this.features = res.featureRole;
        this.langSelected = res.data.language;
        this.translate.use(this.langSelected);  
        localStorage.setItem('language', res.data.language);
        this.communicationService.setLanguage(this.langSelected);

        // this.firstTimeLogin = res.data.firstTimeLogin;
        // if (this.firstTimeLogin) {
        //     $('#welcomeMessage').modal();
        // }

        this.rootCustomer = res.data.rootCustomer;
        this.currentCustomer = res.data.currentCustomer;

        if (this.user.data.preProd && this.user.data.prod) {
          this.isprod = (localStorage.getItem('isProd') === 'true');
          this.toggleDisable = false;
          $(document).ready(() => {
            $('[data-toggle="tooltip"]').tooltip();
          });
        } else if (this.user.data.preProd) {
          localStorage.setItem('isProd', 'false');
          this.isprod = false;
          this.toggleDisable = true;
          $(document).ready(() => {
            $('[data-toggle="tooltip"]').tooltip();
          });
          localStorage.setItem('env', environment.serverUrl);
        } else if (this.user.data.prod) {
          localStorage.setItem('isProd', 'true');
          this.isprod = true;
          this.toggleDisable = true;
          $(document).ready(() => {
            $('[data-toggle="tooltip"]').tooltip();
          });
          localStorage.setItem('env', environment.prodUrl);
        }

        if (this.isprod) {
          $('#sidebar-border-bottom').addClass('border-bottom-thick-gr');
          $('#sidebar-border-bottom').removeClass('border-bottom-thick-wh');
        } else {
          $('#sidebar-border-bottom').removeClass('border-bottom-thick-gr');
          $('#sidebar-border-bottom').addClass('border-bottom-thick-wh');
        }

        if (res) {
          this.socket.emit('csspuser', res.data._id);
          this.socket.on('bellnotification', (notiData) => {
            this.badgeShow = true;
            console.log('notiData>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
            console.log(notiData);
            console.log('notiData>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
            if(notiData.message.includes('PRP Report has been mailed to')) {
              let message = notiData.message.split('to');
              this.translate.get('PRP Report has been mailed to')
                .subscribe((res: any) => {
                  notiData.transMessage = res + message[1];
                });
            } else if(notiData.message.includes('Campaign Generation Report has been mailed to')) {
              let message = notiData.message.split('to');
              this.translate.get('Campaign Generation Report has been mailed to')
                .subscribe((res: any) => {
                  notiData.transMessage = res + ' ' + message[1];
                });
            } else if(notiData.message.includes('Reset Profile Report has been mailed to')) {
              let message = notiData.message.split(' to ');
              this.translate.get('Reset Profile Report has been mailed to')
                .subscribe((res: any) => {
                  notiData.transMessage = res + ' ' + message[1];
                });
            } else if(notiData.message.includes('Prepare Profile Report has been mailed to')) {
              let message = notiData.message.split(' to ');
              this.translate.get('Prepare Profile Report has been mailed to')
                .subscribe((res: any) => {
                  notiData.transMessage = res + ' ' + message[1];
                });
            } else if(notiData.message.includes('QR Code Report has been mailed to')) {
              let message = notiData.message.split(' to ');
              this.translate.get('QR Code Report has been mailed to')
                .subscribe((res: any) => {
                  notiData.transMessage = res + ' ' + message[1];
                });
            } else if(notiData.message.includes('QRCode - ')) {
              let msg1 = notiData.message.split('QRCode');
              let msg2 = msg1[1].split('has been mailed to');
              let qrCode;
              let mailTo;
              this.translate.get('QRCode')
                .subscribe((res: any) => {
                  qrCode = res;
                  notiData.transMessage = qrCode + msg2[0];
                });
            
              this.translate.get('has been mailed to')
                .subscribe((res: any) => {
                  mailTo = res;
                  notiData.transMessage += mailTo + ' ' + msg2[1];
                });
            } else if(notiData.message.includes('Prepare - ')) {
              let msg1 = notiData.message.split('Prepare');
              let msg2 = msg1[1].split('has been mailed to');
              let qrCode;
              let mailTo;
              this.translate.get('Prepare')
                .subscribe((res: any) => {
                  qrCode = res;
                  notiData.transMessage = qrCode + msg2[0];
                });
            
              this.translate.get('has been mailed to')
                .subscribe((res: any) => {
                  mailTo = res;
                  notiData.transMessage += mailTo + ' ' + msg2[1];
                });
            } else if (notiData.message.includes('QR Code Generation Report has been mailed to')) {
              let message = notiData.message.split(' to ');
              this.translate.get('QR Code Generation Report has been mailed to')
                .subscribe((res: any) => {
                  notiData.transMessage = res + ' ' + message[1];
                });
            } else {
              notiData.transMessage = notiData.message;
            }
            this.notificationList.unshift(notiData);
          });
        }

        this.userStore.dispatch(new UserAction.GetUser(res));
      }, err => {
        const cookieTrail = {
          screenName: 'Navbar',
          action: 'Get User',
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });

    this.notificationService.getNotifications()
      .subscribe((res: any) => {
        res.forEach((element, index) => {
          if (index < 10) {
            if(element.message.includes('PRP Report has been mailed to')) {
              let message = element.message.split('to');
              this.translate.get('PRP Report has been mailed to')
                .subscribe((res: any) => {
                  element.transMessage = res + message[1];
                });
            } else if(element.message.includes('Campaign Generation Report has been mailed to')) {
              let message = element.message.split('to');
              this.translate.get('Campaign Generation Report has been mailed to')
                .subscribe((res: any) => {
                  element.transMessage = res + ' ' + message[1];
                });
            } else if(element.message.includes('Reset Profile Report has been mailed to')) {
              let message = element.message.split(' to ');
              this.translate.get('Reset Profile Report has been mailed to')
                .subscribe((res: any) => {
                  element.transMessage = res + ' ' + message[1];
                });
            } else if(element.message.includes('Prepare Profile Report has been mailed to')) {
              let message = element.message.split(' to ');
              this.translate.get('Prepare Profile Report has been mailed to')
                .subscribe((res: any) => {
                  element.transMessage = res + ' ' + message[1];
                });
            } else if(element.message.includes('QR Code Report has been mailed to')) {
              let message = element.message.split(' to ');
              this.translate.get('QR Code Report has been mailed to')
                .subscribe((res: any) => {
                  element.transMessage = res + ' ' + message[1];
                });
            } else if(element.message.includes('QRCode - ')) {
              let msg1 = element.message.split('QRCode');
              let msg2 = msg1[1].split('has been mailed to');
              let qrCode;
              let mailTo;
              this.translate.get('QRCode')
                .subscribe((res: any) => {
                  qrCode = res;
                  element.transMessage = qrCode + msg2[0];
                });
            
              this.translate.get('has been mailed to')
                .subscribe((res: any) => {
                  mailTo = res;
                  element.transMessage += mailTo + ' ' + msg2[1];
                });
            } else if(element.message.includes('Prepare - ')) {
              let msg1 = element.message.split('Prepare');
              let msg2 = msg1[1].split('has been mailed to');
              let qrCode;
              let mailTo;
              this.translate.get('Prepare')
                .subscribe((res: any) => {
                  qrCode = res;
                  element.transMessage = qrCode + msg2[0];
                });
            
              this.translate.get('has been mailed to')
                .subscribe((res: any) => {
                  mailTo = res;
                  element.transMessage += mailTo + ' ' + msg2[1];
                });
            } else if (element.message.includes('QR Code Generation Report has been mailed to')) {
              let message = element.message.split(' to ');
              this.translate.get('QR Code Generation Report has been mailed to')
                .subscribe((res: any) => {
                  element.transMessage = res + ' ' + message[1];
                });
            } else {
              element.transMessage = element.message;
            }
            this.notificationList.push(element);
          }
        });
      }, err => {
        const cookieTrail = {
          screenName: 'Navbar',
          action: 'Get Notifications',
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });

    this.communicationService.getCustomer()
      .subscribe((res: any) => {
        this.customerName = res;
        this.getUser();
      });

    this.communicationService.getLanguage()
      .subscribe((res: any) => {
        this.langSelected = res;
        this.selectedLanguage = this.languageList[this.languageList.findIndex(ele => ele.key === this.langSelected)].name;
      });
    
    this.communicationService.getUserName()
      .subscribe((username: any) => {
        this.displayName = username;
      });
  }

  getUser() {
    this.userStore.select('user')
      .subscribe((user: any) => {
        if(user) {
          this.rootCustomer = user.data.rootCustomer;
          this.currentCustomer = user.data.currentCustomer;
          this.features = user.featureRole;
          if (user.data.preProd && user.data.prod) {
            this.toggleDisable = false;
          } else if (user.data.preProd) {
            this.toggleDisable = true;
          } else if (user.data.prod) {
            this.toggleDisable = true;
          }
        }
      });
  }

  firstTimeLoginUser() {
      this.userService.firstTimeLoginUser({})
      .subscribe((res: any) => {
    }, err => {
      const cookieTrail = {
        screenName: 'Navbar',
        action: 'Disable first time login.',
        error: err.error || 'Something went wrong!',
      };
      this.cookieTrailService.saveCookie(cookieTrail);
      this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
    });
  }

  changeLanguage() {
    const cookieTrail = {
      screenName: 'Navbar',
      action: 'Change Language',
      body: {language: this.langSelected}
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);
    this.userService.setLanguage({language: this.langSelected})
      .subscribe((res: any) => {
        localStorage.setItem('language', this.langSelected);
        this.translate.use(this.langSelected);
        this.communicationService.setLanguage(this.langSelected);
        this.selectedLanguage = this.languageList[this.languageList.findIndex(ele => ele.key === this.langSelected)].name;

        this.notificationList.forEach((element: any) => {
          if(element.message.includes('PRP Report has been mailed to')) {
            let message = element.message.split('to');
            this.translate.get('PRP Report has been mailed to')
              .subscribe((res: any) => {
                element.transMessage = res + message[1];
              });
          } else if(element.message.includes('Campaign Generation Report has been mailed to')) {
            let message = element.message.split('to');
            this.translate.get('Campaign Generation Report has been mailed to')
              .subscribe((res: any) => {
                element.transMessage = res + ' ' + message[1];
              });
          } else if(element.message.includes('Reset Profile Report has been mailed to')) {
            let message = element.message.split(' to ');
            this.translate.get('Reset Profile Report has been mailed to')
              .subscribe((res: any) => {
                element.transMessage = res + ' ' + message[1];
              });
          } else if(element.message.includes('Prepare Profile Report has been mailed to')) {
            let message = element.message.split(' to ');
            this.translate.get('Prepare Profile Report has been mailed to')
              .subscribe((res: any) => {
                element.transMessage = res + ' ' + message[1];
              });
          } else if(element.message.includes('QR Code Report has been mailed to')) {
            let message = element.message.split(' to ');
            this.translate.get('QR Code Report has been mailed to')
              .subscribe((res: any) => {
                element.transMessage = res + ' ' + message[1];
              });
          } else if(element.message.includes('QRCode - ')) {
            let msg1 = element.message.split('QRCode');
            let msg2 = msg1[1].split('has been mailed to');
            let qrCode;
            let mailTo;
            this.translate.get('QRCode')
              .subscribe((res: any) => {
                qrCode = res;
                element.transMessage = qrCode + msg2[0];
              });
            
            this.translate.get('has been mailed to')
              .subscribe((res: any) => {
                mailTo = res;
                element.transMessage += mailTo + ' ' + msg2[1];
            });
          } else if(element.message.includes('Prepare - ')) {
            let msg1 = element.message.split('Prepare');
            let msg2 = msg1[1].split('has been mailed to');
            let qrCode;
            let mailTo;
            this.translate.get('Prepare')
              .subscribe((res: any) => {
                qrCode = res;
                element.transMessage = qrCode + msg2[0];
              });
            
            this.translate.get('has been mailed to')
              .subscribe((res: any) => {
                mailTo = res;
                element.transMessage += mailTo + ' ' + msg2[1];
            });
          } else if (element.message.includes('QR Code Generation Report has been mailed to')) {
            let message = element.message.split(' to ');
            this.translate.get('QR Code Generation Report has been mailed to')
              .subscribe((res: any) => {
                element.transMessage = res + ' ' + message[1];
              });
          } else {
            element.transMessage = element.message;
          }
        });

      }, err => {
        const cookieTrail = {
          screenName: 'Navbar',
          action: 'Change Language',
          body: {language: this.langSelected},
          error: err.error || 'Something went wrong!'
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      })
  }

  sidebarToggle() {
    $('#sidebar-toggle').toggleClass('toggle-sidebar-on');
    $('#sidebar-toggle').toggleClass('toggle-sidebar-on-powered');
  }

  signout() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('role');
    localStorage.removeItem('currentCustomer');
    localStorage.removeItem('rootCustomer');
    localStorage.removeItem('customerId');
    localStorage.removeItem('filteTerms');
    localStorage.removeItem('manageGroupQrfilteTerms');
    localStorage.removeItem('notificationMode');
    this.authService.signout();
  }

  changeEnvironment() {

    this.isprod = !this.isprod;

    localStorage.setItem('isProd', String(this.isprod));
    localStorage.removeItem('filteTerms');
    localStorage.removeItem('manageGroupQrfilteTerms');

    if (this.isprod) {
      $('#sidebar-border-bottom').addClass('border-bottom-thick-gr');
      $('#sidebar-border-bottom').removeClass('border-bottom-thick-wh');
      localStorage.setItem('env', environment.prodUrl);
    } else {
      $('#sidebar-border-bottom').removeClass('border-bottom-thick-gr');
      $('#sidebar-border-bottom').addClass('border-bottom-thick-wh');
      localStorage.setItem('env', environment.serverUrl);
    }
    this.userStore.select('user')
      .subscribe((res: any) => {
        this.features = res.featureRole;
    });
    window.location.href = this.features[this.getMainFeatureIndex('Dashboard')].display ? '/dashboard' : '/dashboard-user';
  }

  getMainFeatureIndex(name) {
    return this.features ? this.features.findIndex(ele => ele.name === name) : '';
  }
}
