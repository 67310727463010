import { Action } from '@ngrx/store';
import { Filter } from './filter.model';

export const GET_FILTER = 'GET_FILTER';
export const CLEAR_FILTERS = '[Filter] Clear Filters';

export class GetFilter implements Action {
    readonly type = GET_FILTER;

    constructor(public payload: Filter) { }
}

export class ClearFilters implements Action {
    readonly type = CLEAR_FILTERS;
    constructor(public payload: Filter) { }
}

export type FilterActions = GetFilter | ClearFilters;
