import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import * as io from 'socket.io-client';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserService } from './shared/services/user.service';
import { AuthService } from './shared/services/auth.service';
import { CommunicationService } from './shared/services/communication.service';
import { TranslateService } from '@ngx-translate/core';
import { RoutingStateService } from './shared/services/routing-state.service';
import { CookieTrailService } from './shared/services/cookie-trail.service';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'cssp-application';
  currentUrl;
  socket;
  user;
  readyToLoad = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private userService: UserService,
    private authService: AuthService,
    private communicationService: CommunicationService,
    private translate: TranslateService,
    routingState: RoutingStateService,
    private cookieTrailService: CookieTrailService) {
    routingState.loadRouting();
    let env = 'preprod-cssp';
    if (localStorage.getItem('isProd') && (localStorage.getItem('isProd') === 'true')) {
      env = 'prod-cssp';
    }
    this.socket = io.connect(environment.notifUrl, { 
      path: `/${env}/socket.io`,
      // withCredentials: false,
    });
    translate.addLangs(environment.languageArray);
    translate.setDefaultLang('en');
    // const browserLang = translate.getBrowserLang();
    let browserLang = localStorage.getItem('language');
    if (!browserLang) {
      browserLang = 'en';
      localStorage.setItem('language', browserLang);
    }
    translate.use(browserLang.match(environment.langRegex) ? browserLang : 'en');
  }

  ngOnInit() {
    this.http.get('assets/server-info.text', { responseType: 'text' as 'json' }).subscribe(data => {
      console.log('ip and hostname: ' + data);
    });

    $(document).unbind('keydown').bind('keydown', (event) => {
      if (event.keyCode === 8) {
        let doPrevent = true;
        const types = [
          'text',
          'password',
          'file',
          'search',
          'email',
          'number',
          'date',
          'color',
          'datetime',
          'datetime-local',
          'month',
          'range',
          'search',
          'tel',
          'time',
          'url',
          'week',
          'select'
        ];
        const d = $(event.srcElement || event.target);
        const disabled = d.prop('readonly') || d.prop('disabled');
        if (!disabled) {
          if (d[0].isContentEditable) {
            doPrevent = false;
          } else if (d.is('input')) {
            let type = d.attr('type');
            if (type) {
              type = type.toLowerCase();
            }
            if (types.indexOf(type) > -1) {
              doPrevent = false;
            }
            if(d[0].id === 'country-search-box') {
              doPrevent = false;
            }
          } else if (d.is('textarea')) {
            doPrevent = false;
          }
        }
        if (doPrevent) {
          event.preventDefault();
          return false;
        }
      }
    });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (window.location.pathname) {
          this.currentUrl = window.location.pathname;
        } else {
          this.currentUrl = val.url;
        }
      }
    });

    if (localStorage.getItem('authToken')) {
      this.authService.getRefreshToken();
      this.userService.getUser()
        .subscribe((res: any) => {
          this.user = res;

          this.translate.use(res.data.language);  
          localStorage.setItem('language', res.data.language);
          this.communicationService.setLanguage( res.data.language);

          if (this.user.data.preProd && this.user.data.prod) {
            if (!localStorage.getItem('isProd') || !localStorage.getItem('env')) {
              localStorage.setItem('isProd', 'false');
              localStorage.setItem('env', environment.serverUrl);
            }
          } else if (this.user.data.preProd) {
            localStorage.setItem('isProd', 'false');
            localStorage.setItem('env', environment.serverUrl);
          } else if (this.user.data.prod) {
            localStorage.setItem('isProd', 'true');
            localStorage.setItem('env', environment.prodUrl);
          } else {
            ($('#envAccess') as any).modal({ backdrop: 'static', keyboard: false });
            return;
          }
          this.readyToLoad = true;

        }, err => {
          const cookieTrail = {
            screenName: 'Main App',
            action: 'Get User',
            error: err.error || 'Something went wrong!',
          };
          
          this.cookieTrailService.saveCookie(cookieTrail);
          this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
        });
    }
  }

  signout() {
    $('#envAccess').modal('hide');
    localStorage.removeItem('authToken');
    localStorage.removeItem('role');
    localStorage.removeItem('customerId');
    localStorage.removeItem('filteTerms');
    localStorage.removeItem('manageGroupQrfilteTerms');
    localStorage.removeItem('notificationMode');
    this.authService.signout();
  }
}
