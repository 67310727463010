import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CommunicationService } from '../shared/services/communication.service';
import { CustomerService } from '../shared/services/customer.service';
import { GroupMgmtService } from '../shared/services/group-mgmt.service';
import { UserService } from '../shared/services/user.service';
import { UserState } from '../shared/stores/user/user.reducer';
import * as UserAction from '../shared/stores/user/user.action';
import { TranslateService } from '@ngx-translate/core';
import { CookieTrailService } from '../shared/services/cookie-trail.service';
import { environment } from '../../environments/environment';
import { SidebarComponent } from '../shared/component/sidebar/sidebar.component';
import { Subscription } from 'rxjs';
import { HierarchyState } from '../shared/stores/hierarchy-tree/hierarchy.reducer';
import * as HierarchyAction from '../shared/stores/hierarchy-tree/hierarchy.action';

declare var $: any;
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {
  environments = environment.environments || [ 'PROD', 'PRE-PROD' ];
  groupDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    selectAllText: 'Select All',
    searchPlaceholderText: 'Search',
    textField: 'groupName',
    allowSearchFilter: true,
    itemsShowLimit: 2,
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'No data available'
  };
  selectedGroups;
  groupList;
  originalGroupList;
  customerId;
  customerList;
  customerSpinner = false;
  createCustomerSpinner = false;
  customerName;
  customerNameErr = false;
  editOn;
  isProd;
  groupCustId;
  index;
  ngSelect = false;
  rootCustomerId;
  user;
  disableCreate = true;
  selectedIndex;
  deleteSpinner = false;
  multitenant = false;
  features;
  show = false;
  customerListSpinner = false;
  customIndex;
  deleteCustomerId;
  deleteCustomerName;
  isChild = true;
  currentLevel = 0;
  customerListSubscription: Subscription;
  groupCustomerListSubscription: Subscription;
  customerLevelSubscription: Subscription;
  isRoot = true;
  addCustomerBtnSpinner = false;
  whiteLabelEnable = environment.whiteLabelEnable;
  smdpListRaw = [];
  smdpList = [];
  smdpDropdownSettings = {
    singleSelection: true,
    idField: '_id',
    textField: 'name',
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'No data available'
  };
  customerMnoId;
  customerMnoIdErr = false;

  customerPreProdMnoId;
  customerPreProdMnoIdErr = false;

  gridWrapper = '1fr 200px 200px';

  envModels = [];

  constructor(
    private groupService: GroupMgmtService,
    private communicationService: CommunicationService,
    private activateRoute: ActivatedRoute,
    private customerService: CustomerService,
    private userService: UserService,
    private userStore:Store<UserState>,
    private translate: TranslateService,
    private cookieTrailService: CookieTrailService, 
    private sidebar: SidebarComponent,
    private route: ActivatedRoute,
    private router: Router,
    private hierarchyStore: Store<HierarchyState>) { 
      if(window.location.pathname.includes('/customer')) {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
      }
      
      translate.addLangs(environment.languageArray);
      translate.setDefaultLang('en');
      let browserLang = localStorage.getItem('language');
      if (!browserLang) {
        browserLang = 'en';
        localStorage.setItem('language', browserLang);
      }
      translate.use(browserLang.match(environment.langRegex) ? browserLang : 'en');

      this.translate.get('Select All').subscribe((res: string) => {
        this.show = false;
        this.groupDropdownSettings.selectAllText = res;
        this.refreshDropSettings();
      });

      this.translate.get('Search').subscribe((res: string) => {
        this.show = false;
        this.groupDropdownSettings.searchPlaceholderText = res;
        this.refreshDropSettings();
      });

      this.translate.get('UnSelect All').subscribe((res: string) => {
        this.show = false;
        this.groupDropdownSettings.unSelectAllText = res;
        this.refreshDropSettings();
      });

      this.translate.get('No data available').subscribe((res: string) => {
        this.show = false;
        this.groupDropdownSettings.noDataAvailablePlaceholderText = res;
        this.refreshDropSettings();
      });
    }

  ngOnInit() {
    this.isProd = localStorage.getItem('isProd') === 'true' ? true : false;
    this.activateRoute.params
      .subscribe((params: any) => {
        this.customerId = '';
        this.isRoot = true;

        if(params.custId) {
          this.customerId = params.custId;
          this.isRoot = false;
          this.getGroupCustomer();
        } else {
          let customer = JSON.parse(localStorage.getItem('rootCustomer'));

          if(JSON.parse(localStorage.getItem('currentCustomer'))) {
            this.user = JSON.parse(localStorage.getItem('currentCustomer'));
            if(this.user.isRoot) { 
              this.getGroupCustomer();
              this.customerId = '';
              this.isRoot = true;
            } else {
              this.customerId = this.user._id
              this.getGroupCustomer();
              this.isRoot = false;
            }
          } else if(customer.isRoot) {
            this.isRoot = true;
            this.getCustomersList();
          } else {
            this.customerId = JSON.parse(localStorage.getItem('rootCustomer'))._id;
            this.isRoot = true;
            this.getGroupCustomer();
          }
        }
        if(this.customerLevelSubscription) {
          this.customerLevelSubscription.unsubscribe();
        }
        this.disableCreate = true;
        this.getCustomerLevel();
      });

    this.getAllGroups();

    this.communicationService.getLanguage()
      .subscribe((res: any) => {
        this.changeLanguage();
      });

    this.userStore.select('user')
      .subscribe((user: any) => {
        if(user && user.data.currentCustomer) {
          this.multitenant = localStorage.getItem('isProd') === 'true' ? user.data.currentCustomer.prodMultiTenant : user.data.currentCustomer.preProdMultiTenant;
        }
      });

    if (this.whiteLabelEnable) {
      this.getAllSmdp();
    }

    this.route.params.subscribe((params: any) => {
      if(Number(Object.keys(params)) <= 0) {
        this.getCustomersList()
      }
    });
  
    if(this.customerLevelSubscription) {
      this.customerLevelSubscription.unsubscribe();
    }
    this.disableCreate = true;
    this.getCustomerLevel();

    this.initEnvModel();
  }

  initEnvModel() {
    this.envModels = this.environments.map(env => ({
      env,
      customerMnoId: null,
      customerMnoIdErr: false
    }))
  }

  getHeader(env) {
    return `${env} Mno Id`;
  }

  getMnoIdErrorMessage (env) {
    return `Please enter customer ${this.getHeader(env)}`
  }

  getGridWrapper() {
    if (!this.customerId || !this.isRoot) {
      return undefined;
    }
    const extraWrapper = this.environments.reduce((acc) => acc += ' 200px', '');
    return this.gridWrapper + extraWrapper;
  }

  getModalHeader(pre) {
    if (!this.customerId || !this.isRoot) {
      return `${pre} Tenant`;
    }
    return `${pre} Customer`;
  }

  refreshDropSettings() {
    setTimeout(() => {
      this.show = true;
    }, 1000);
  }

  changeLanguage() {
    const lang = localStorage.getItem('language');
    this.translate.use(lang);

    this.translate.get('Select All').subscribe((res: string) => {
      this.show = false;
      this.groupDropdownSettings.selectAllText = res;
      this.refreshDropSettings();
    });

    this.translate.get('Search').subscribe((res: string) => {
      this.show = false;
      this.groupDropdownSettings.searchPlaceholderText = res;
      this.refreshDropSettings();
    });

    this.translate.get('UnSelect All').subscribe((res: string) => {
      this.show = false;
      this.groupDropdownSettings.unSelectAllText = res;
      this.refreshDropSettings();
    });

    this.translate.get('No data available').subscribe((res: string) => {
      this.show = false;
      this.groupDropdownSettings.noDataAvailablePlaceholderText = res;
      this.refreshDropSettings();
    });
  }
  
  getAllGroups() {
    this.groupService.getAllGroups()
      .subscribe((res: any) => {
        this.groupList = res;
        this.originalGroupList = res;
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'})
      });
  }

  getAllSmdp() {
    this.groupService.getAllSmdp()
      .subscribe((res: any) => {
        if (res.smdpList.length > 0) {
          this.smdpList = res.smdpList;
          if (this.smdpList.length > 0) {
            this.customerId = JSON.parse(localStorage.getItem('rootCustomer'))._id;
          }
        }
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'})
      });
  }

  selectGroups() {}

  getGroupCustomer() {
    this.customerListSpinner = true;

    if(this.customerListSubscription) {
      this.customerListSubscription.unsubscribe();
    }

    this.groupCustomerListSubscription = this.customerService.getGroupCustomer(this.customerId)
      .subscribe((res: any) => {
        this.customerListSpinner = false;
          this.customerList = res.customerList;
      }, err => {
        const cookieTrail = {
          screenName: 'Customer Management',
          action: 'Get Group Customer',
          body: this.customerId,
          error: err.error || 'Something went wrong!',
        };
        this.cookieTrailService.saveCookie(cookieTrail);

        this.customerListSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'})
      });
  }

  createCustomer() {
    if(!this.customerName || (this.customerName && !this.customerName.trim())) {
      this.customerNameErr = true;
      return;
    }

    if (this.whiteLabelEnable && this.isRoot) {
      for (const env of this.envModels) {
        if (!env.customerMnoId) {
          env.customerMnoIdErr = true;
          return;
        }
      }
    }

    let customerData;
    if(this.isProd) {
      customerData = {
        prodName: this.customerName,
        groups: this.selectedGroups
      };
    } else {
      customerData = {
        name: this.customerName,
        groups: this.selectedGroups
      };
    }

    customerData = {
      whiteLabelRoot: this.whiteLabelEnable && this.isRoot,
      ...customerData,
      mnoIds: this.envModels.reduce((acc, curr) => ({ ...acc, [curr.env]: curr.customerMnoId }), {}),
      smdpData: this.smdpList[0]
    };

    const cookieTrail = {
      screenName: 'Customer Management',
      action: 'Create Customer',
      body: customerData
    };
    this.cookieTrailService.saveCookie(cookieTrail);
    this.createCustomerSpinner = true;
    this.customerService.createCustomer(this.customerId, customerData)
      .subscribe((res: any) => {
        this.createCustomerSpinner = false;
        this.customerList.push(res);
        this.communicationService.sendCustomerList()
        $('#createCustomer').modal('hide');
        this.communicationService.openAlert({alertType: 'success', alertMsg: 'Customer Created Successfully!'});
      }, err => {
        const cookieTrail = {
          screenName: 'Customer Management',
          action: 'Create Customer',
          body: customerData,
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);

        this.createCustomerSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'})
      });
  }

  editCustomer() {
    if(!this.customerName || (this.customerName && !this.customerName.trim())) {
      this.customerNameErr = true;
      return;
    }

    if (this.whiteLabelEnable && this.isRoot) {
      for (const env of this.envModels) {
        if (!env.customerMnoId) {
          env.customerMnoIdErr = true;
          return;
        }
      }
    }

    let customerData;
    if(this.isProd) {
      customerData = {
        prodName: this.customerName,
        groups: this.selectedGroups
      };
    } else {
      customerData = {
        name: this.customerName,
        groups: this.selectedGroups
      };
    }

    customerData = {
      whiteLabelRoot: this.whiteLabelEnable && this.isRoot,
      ...customerData,
      mnoIds: this.envModels.reduce((acc, curr) => ({ ...acc, [curr.env]: curr.customerMnoId }), {}),
      smdpData: this.smdpList[0],
    };

    const cookieTrail = {
      screenName: 'Customer Management',
      action: 'Edit Customer',
      body: customerData
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);
    if (customerData.groups === undefined){
      customerData.groups = [];
    }
    this.createCustomerSpinner = true;
    this.customerService.editGroupCustomer(this.groupCustId, customerData)
      .subscribe((res: any) => {
        this.selectedGroups = [];
        this.getGroupCustomer()
        this.createCustomerSpinner = false;
        this.communicationService.sendCustomerList()
        $('#createCustomer').modal('hide');
        this.communicationService.openAlert({alertType: 'success', alertMsg: 'Customer Edited Successfully!'});
      }, err => {
        const cookieTrail = {
          screenName: 'Customer Management',
          action: 'Edit Customer',
          body: customerData,
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);

        this.createCustomerSpinner = false;
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'})
      });
  }

  gotoCustomer(id, path) {
    this.customerId = id;
    this.disableCreate = true;
    this.customerService.selectCustomer(id)
      .subscribe((res: any) => {
        localStorage.removeItem('filteTerms');
        localStorage.removeItem('manageGroupQrfilteTerms');
        this.getUser();
        this.router.navigate(path);
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'})
      });
  }

  getUser() {
    this.userService.getUser()
      .subscribe((res: any) => {
        this.userStore.dispatch(new UserAction.GetUser(res));
        this.sidebar.getProfileGroup();
        this.sidebar.getTheme();
        this.communicationService.sendRemovedCustomer();
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'})
      });
  }

  getSingleCustomer(index, jIndex = undefined, kIndex = undefined, lIndex = undefined, mIndex = undefined, nIndex = undefined, oIndex = undefined) {
    this.customIndex = [index, jIndex, kIndex, lIndex, mIndex, nIndex, oIndex].filter(ele => ele != undefined);
    this.index = index;
    this.ngSelect = false;
    let tempCustomer;
    this.customIndex.forEach((element, ind) => {
      if(ind === 0) {
        tempCustomer = this.customerList[element];
      } else {
        tempCustomer = tempCustomer.child[element]
      }
    });
    if(localStorage.getItem('isProd') === 'true') {
      this.customerName = tempCustomer.prodName;
    } else if(localStorage.getItem('isProd') === 'false') {
      this.customerName = tempCustomer.name;
    }
    this.groupCustId = tempCustomer._id;
    this.selectedGroups = tempCustomer.groups;
    
    this.ngSelect = true;
  }

  isSpace(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode === 32 && !this.customerName.trim()) {
        return false;
    }
    return true;
  }

  getUnAssignedGroups(parentId) {
    // this.smdpList = this.smdpListRaw.map(s => s);
    // this.smdpListSelection = true;
    const cookieTrail = {
      screenName: 'Customer Management',
      action: 'Get Unassigned Groups',
    };
    this.cookieTrailService.saveCookie(cookieTrail);
    if(parentId) {
      this.groupService.getUnassignedGroupList(parentId)
      .subscribe((res: any) => {
        if(this.selectedGroups) {
          this.groupList = [...this.selectedGroups, ...res];
        } else {
          this.groupList = res;
        }
      }, err => {
        const cookieTrail = {
          screenName: 'Customer Management',
          action: 'Get Unassigned Groups',
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
    }
  }

  deleteCustomer() {
    const cookieTrail = {
      screenName: 'Customer Management',
      action: 'Delete Customer',
      body: {
        customerId: this.deleteCustomerId
      }
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);

    this.deleteSpinner = true;

    this.customerService.deleteCustomer(this.deleteCustomerId)
      .subscribe((res: any) => {
        this.deleteSpinner = false;
        $('#deleteCustomerModal').modal('hide');
        this.getGroupCustomer()
        this.communicationService.sendCustomerList();
        this.communicationService.openAlert({alertType: 'success', alertMsg: 'Customer Deleted Successfully!'});
      }, err => {
        this.deleteSpinner = false;
        $('#deleteCustomerModal').modal('hide');
        const cookieTrail = {
          screenName: 'Customer Management',
          action: 'Delete Customer',
          body: {
            customerId: this.customerList[this.selectedIndex]._id
          },
          error: err.error || 'Something went wrong!'
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
  }

  getChildCustomers(id) {
    this.customerListSpinner = true;
    this.customerService.getGroupCustomer(id).subscribe((res: any) => {
      this.customerListSpinner = false;
      this.customerList = res.customerList;
    })
  }

  getCustomersList() {
    this.customerListSpinner = true;
    if(this.groupCustomerListSubscription) {
      this.groupCustomerListSubscription.unsubscribe();
    }
    this.customerListSubscription = this.customerService.getCustomers()
      .subscribe((res: any) => {
        this.customerListSpinner = false;
        this.customerList = res.customerList;
      }, err => {
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });  
  }

  getCustomerLevel() {
    this.disableCreate = true;
    if(this.customerLevelSubscription) {
      this.customerLevelSubscription.unsubscribe();
    }
    this.addCustomerBtnSpinner = true;
    this.customerLevelSubscription = this.customerService.customerLevel()
    .subscribe((res: any) => {
      this.disableCreate = !res.childAllowed;
      this.hierarchyStore.dispatch(new HierarchyAction.GetHierarchy(res.parentCustomer));
      this.addCustomerBtnSpinner = false;
    }, (err) => {
      this.disableCreate = true;
      this.addCustomerBtnSpinner = false;
    });
  }
  getMnoIdentifier(customer, env: string) {
    return customer?.mnos?.find((mno) => mno.env === env)?.mnoIdentifier
  }
  updateEnvModel(customer) {
    this.envModels = this.envModels.map(env => {
      return {
        ...env,
        customerMnoId: this.getMnoIdentifier(customer, env.env),
      }
    })
  }
}