export const environment = {
    production: true,
    platformName: 'MyOASIS',
    environments: [
      "PROD",
      "PRE-PROD",
    ],
    serverUrl: 'https://oasis-ga-api.10ttech.com/preprod-cssp/gsma/rsp2/es2plus',
    prodUrl: 'https://oasis-ga-api.10ttech.com/prod-cssp/gsma/rsp2/es2plus',
    authUrl: 'https://oasis-ga-api.10ttech.com/user-api',
    notifUrl: 'https://oasis-ga-api.10ttech.com',
    languageArray: ['en', 'es', 'ru', 'pt', 'sv', 'zh', 'bm'],
    langRegex: /en|es|ru|mr|pt|sv|zh|bm/,
    languageList: [
      { name: 'English', key: 'en' },
      { name: 'Russian', key: 'ru' },
      { name: 'Portuguese', key: 'pt' },
      { name: 'Marathi', key: 'mr' },
      { name: 'Swedish', key: 'sv'},
      { name: 'Simplified Chinese', key: 'zh'},
      { name: 'Bahasa Malaysia', key: 'bm'}
    ],
    qrCodeOptions: ['QR Code'],
    displayOrCodeValueFlgs: ['QR Code'],
    disabledRoles: [
      'campaign', 'campaign_generate',
      'gov_report', 'gov_download', 'gov_email',
      'api_user', 'api_developer', 'api_bookmark',
      'profile_group', 'groupqr_with_logo', 'groupqr_with_campaign', 'group_qr_code',
      'prp_setting', 'campaign_in_qrcode', 'campaign_in_prepare', 'qrcode_with_logo',
      'prepare_report', 'reset_report', 'qrcode_report', 'inventory_report', 'device_analytics', 'profile_download',
    ],
    whiteLabelEnable: true,
    whiteLabelHelpDesk: 'https://tickets.oasis-smartsim.com/',
    whiteLabelPlaceholderSearchString: 'Search by ICCID / EID number',
    whiteLabelLogo: 'Logo-Oasis.png',
    whiteLabelMainLightLogo: 'assets/images/logo/oasis-logo-light.png',
    whiteLabelSmallLightLogo: 'assets/images/logo/oasis-logo-small.svg',
    whiteLabelMainDarkLogo: 'assets/images/logo/oasis-logo-dark.png',
    whiteLabelSmallDarkLogo: 'assets/images/logo/oasis-logo-small.svg',
    whiteLabeSigninTheme: {
      rightColor: '#cc1b33',
      leftColor: '#fff',
      font: '#fff',
      button: '#000',
      buttonTextColor: '#fff',
    },
    whiteLabeDefaultDarkTheme: {
      sideBarColor: '#343e48',
      backgroundColor: '#1f1e1e',
      fontColor: '#ed2738',
      buttonColor: '#ed2738'
    },
    whiteLabeDefaultLightTheme: {
      sideBarColor: '#FFFFFF',
      backgroundColor: '#FFFFFF',
      fontColor: '#ed2738',
      buttonColor: '#ed2738'
    },
  };
  