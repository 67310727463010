import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorLuminanceService {

  constructor() { }

  convertHexToRgb(hex: string): number[] {
    hex = hex.replace(/^#/, '');
    if (hex.length === 3) {
      hex = hex.split('').map(c => c + c).join('');
    }
    
    const bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
    return [r, g, b];
  }
  
  getColorLuminance(hex: string): number {
    const rgb = this.convertHexToRgb(hex);
    let r = rgb[0] / 255;
    let g = rgb[1] / 255;
    let b = rgb[2] / 255;
    let luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luminance;
  }

  convertHexToHsl(hex: string): number[] {
    let rgb = this.convertHexToRgb(hex);

    // Convert from RGB to HSL
    rgb[0] /= 255;
    rgb[1] /= 255;
    rgb[2] /= 255;

    let cmin = Math.min(rgb[0],rgb[1],rgb[2]),
        cmax = Math.max(rgb[0],rgb[1],rgb[2]),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;
  
    if (delta == 0)
      h = 0;
    else if (cmax == rgb[0])
      h = ((rgb[1] - rgb[2]) / delta) % 6;
    else if (cmax == rgb[1])
      h = (rgb[2] - rgb[0]) / delta + 2;
    else
      h = (rgb[0] - rgb[1]) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0)
      h += 360;

    l = (cmax + cmin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return [Math.round(h), Math.round(s), Math.round(l)];
  }   

  convertHslToHex(h,s,l): string {
    // convert HSL to RGB
    s /= 100;
    l /= 100;

    let c = (1 - Math.abs(2 * l - 1)) * s,
        x = c * (1 - Math.abs((h / 60) % 2 - 1)),
        m = l - c/2,
        r = 0,
        g = 0, 
        b = 0; 

    if (0 <= h && h < 60) {
      r = c; g = x; b = 0;
    } else if (60 <= h && h < 120) {
      r = x; g = c; b = 0;
    } else if (120 <= h && h < 180) {
      r = 0; g = c; b = x;
    } else if (180 <= h && h < 240) {
      r = 0; g = x; b = c;
    } else if (240 <= h && h < 300) {
      r = x; g = 0; b = c;
    } else if (300 <= h && h < 360) {
      r = c; g = 0; b = x;
    }

    // convert RGB into hex
    return "#" + Math.round((r + m) * 255).toString(16).padStart(2, '0') + Math.round((g + m) * 255).toString(16).padStart(2, '0') + Math.round((b + m) * 255).toString(16).padStart(2, '0');
  }

  convertColorByLuminance(color: string, luminance: number): string {
    let colorInHsl = this.convertHexToHsl(color);
    return this.convertHslToHex(colorInHsl[0], colorInHsl[1], luminance * 100); // convert existing color with given luminance value in percentage
  }
}
