<div class="inner-main-profile">
    <div class="result-row">
        <div class="result-table">
            <div class="face-container-inner-bg user-management-table">
                <div class="profile-manage-row result-not-found-row">
                    <h4 class="result-not-found-row-heading">{{'User Management' | translate}}</h4>
                    <input class="search-user t-head-bg" type="search" [(ngModel)]="searchTerm" (ngModelChange)="searchUser(userList, searchTerm);" placeholder="{{'Search here...' | translate}}" name="" id="">
                    <i class="fa fa-search t-color" style="margin-left: -20px;"></i>
                    <div class="d-flex ml-auto">
                        <button class="btn t-bgcolor mr-3 outline button-fix" data-toggle="modal" data-target="#assignBulkUsers" (click)="selectedRoles=[];selectedGroups=[];selectedProfiles=[];" [disabled]="selectedUserList && selectedUserList.length <= 0"> <i class="fa fa-plus mr-2" aria-hidden="true"></i>{{'Update Bulk Users' | translate}}</button>
                        <button class="btn t-bgcolor mr-3 outline button-fix" data-toggle="modal" data-target="#createBulkUsers" (click)="validJson=[];invalidJson=[];lineNos=[];error=false;fileName='';fileToUpload='';successArr=[];failArr=[];btnSpinner=false;"> <i class="fa fa-plus mr-2" aria-hidden="true"></i>{{'Add Bulk Users' | translate}}</button>
                        <button class="btn t-bgcolor outline button-fix" (click)="getProfileTypesWithGroupQR(); btnSpinner=false; openCreateModal();selectedProfileTypes=[];selectedGroups=[];roleErr = false;createModal=true;"> <i class="fa fa-plus mr-2" aria-hidden="true"></i>{{'Create New User' | translate}}</button>
                    </div>
                </div>
                <div class="t-grid-table-new ">
                    <div class="t-grid-table-wrapper t-head-bg border-bottom-light" [ngClass]="{'t-grid-table-wrapper-2': !selectProfileFlg }">
                        <div class="t-box b">
                            <div class="filter-box-checkbox-container">
                                <mat-checkbox [(ngModel)]="select" (click)="selectAllRows();getSelectedCount();" *ngIf="userList && userList.length > 0" ></mat-checkbox>
                            </div>
                        </div>
                        <div class="t-box b">
                            <span class="mr-2">{{'First Name' | translate}}</span><i class="fa fa-long-arrow-up mr-1 cursor-p" [ngClass]="{'c-color': sortBy === 'FIRSTNAME' && orderBy === 'ASC'}" (click)="sortBy = 'FIRSTNAME'; orderBy = 'ASC'; sortList(sortBy,orderBy);" ></i>
                            <i class="fa fa-long-arrow-down cursor-p" [ngClass]="{'c-color': sortBy === 'FIRSTNAME' && orderBy === 'DESC'}" (click)="sortBy = 'FIRSTNAME'; orderBy = 'DESC'; sortList(sortBy,orderBy);"></i>
                        </div>
                        <div class="t-box c">
                            <span class="mr-2">{{'Last Name' | translate}}</span><i class="fa fa-long-arrow-up mr-1 cursor-p" [ngClass]="{'c-color': sortBy === 'LASTNAME' && orderBy === 'ASC'}" (click)="sortBy = 'LASTNAME'; orderBy = 'ASC'; sortList(sortBy,orderBy);" ></i>
                            <i class="fa fa-long-arrow-down cursor-p" [ngClass]="{'c-color': sortBy === 'LASTNAME' && orderBy === 'DESC'}" (click)="sortBy = 'LASTNAME'; orderBy = 'DESC'; sortList(sortBy,orderBy);" ></i>
                        </div>
                        <div class="t-box c">
                            <span class="mr-2">{{'Email' | translate}}</span><i class="fa fa-long-arrow-up mr-1 cursor-p" [ngClass]="{'c-color': sortBy === 'EMAIL' && orderBy === 'ASC'}" (click)="sortBy = 'EMAIL'; orderBy = 'ASC'; sortList(sortBy,orderBy);"></i>
                            <i class="fa fa-long-arrow-down cursor-p" [ngClass]="{'c-color': sortBy === 'EMAIL' && orderBy === 'DESC'}" (click)="sortBy = 'EMAIL'; orderBy = 'DESC'; sortList(sortBy,orderBy);"></i>
                        </div>
                        <div class="t-box c">{{'Environment' | translate}}</div>
                        <div class="t-box c">{{'Role' | translate}}</div>
                        <div class="t-box c"*ngIf="!selectProfileFlg">{{'Groups' | translate}}</div>
                        <div class="t-box c t-color cursor-p text-center" (click)="deleteSelectedUser()" [ngClass]="{'disable-button': selectedUserList && selectedUserList.length <= 0}">
                            <i class="fa fa-trash t-color " style="font-size: 22px;"></i>
                            {{'Delete Selected' | translate}}
                        </div>
                    </div>
                    <div class="t-body-overflow accordion user-management-tbody">
                        <ng-container *ngFor="let singleUser of userList; let i = index">
                            <div class="t-grid-table-wrapper t-body-bg" [ngClass]="{'t-grid-table-wrapper-2': !selectProfileFlg }">
                                <div class="t-box d t-font-light filter-box-checkbox-container">
                                    <mat-checkbox [(ngModel)]="singleUser.checked" (ngModelChange)="selectCheck(singleUser.checked, singleUser.userId);" *ngIf="user.data.email !== singleUser.email"></mat-checkbox>
                                </div>
                                <div class="t-box d t-font-light overflow-auto">{{singleUser.firstName}}</div>
                                <div class="t-box d t-font-light overflow-auto">{{singleUser.lastName}}</div>
                                <div class="t-box d t-font-light overflow-auto">{{singleUser.email}}</div>
                                <div class="t-box d t-font-light">
                                    <span *ngIf="singleUser.prod && singleUser.preProd">Preprod / Prod</span>
                                    <span *ngIf="singleUser.prod && !singleUser.preProd">Prod</span>
                                    <span *ngIf="!singleUser.prod && singleUser.preProd">Preprod</span>
                                    <span *ngIf="!singleUser.prod && !singleUser.preProd">NA</span>
                                </div>
                                <div class="t-box d t-font-light">
                                    <span *ngIf="singleUser.roleId && singleUser.roleId.length === 0">NA</span>
                                        <span *ngIf="singleUser.roleId && singleUser.roleId.length > 0">
                                            <span *ngFor="let role of singleUser.roleId; let i = index;">
                                                {{role.roleName ? role.roleName : ''}}<span *ngIf="singleUser.roleId.length !== i+1">, </span>
                                            </span>
                                        </span>
                                </div>
                                <div class="t-box d t-font-light" *ngIf="!selectProfileFlg">
                                    <span *ngIf="!singleUser.groups || (singleUser.groups && singleUser.groups.length === 0)">NA</span>
                                    <span *ngIf="singleUser.groups && singleUser.groups.length > 0"  class="left-scroller-to-field">
                                        <span *ngFor="let group of singleUser.groups; let i = index;">
                                            {{group.groupName ? group.groupName : ''}}<span *ngIf="singleUser.groups.length !== i+1">, </span>
                                        </span>
                                    </span>
                                </div>
                                <div class="t-box text-center d t-font-light profile-manage-icon-row text-right">
                                    <i class="fa fa-pencil-square-o t-color cursor-p" aria-hidden="true" (click)="editOn = true;getProfileTypesWithGroupQR();getUser(i);btnSpinner=false;" tooltip="{{'Edit User' | translate}}" placement="top" *ngIf="user.data.email !== singleUser.email"></i>
                                    <i class="fa fa-trash t-color cursor-p" data-toggle="modal" data-target="#deletUserModal" aria-hidden="true" tooltip="{{'Delete User' | translate}}" placement="top" (click)="fname = singleUser.firstName; lname = singleUser.lastName; index = i;btnSpinner=false;" *ngIf="user.data.email !== singleUser.email"></i>
                                </div>
                            </div>
                        </ng-container>
                        <div *ngIf="userList && userList.length <= 0">
                            <div class="row d-block py-3 p-2">
                                <div>
                                    <div class="text-center">{{'No Users Found' | translate}}.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="table-responsive">
                    <table class="table t-table table-scroll">
                        <thead>
                            <tr>
                                <th class="t-col-normal">
                                    <div class="filter-box-checkbox-container">
                                        <mat-checkbox [(ngModel)]="select" (click)="selectAllRows();getSelectedCount();" *ngIf="userList && userList.length > 0"></mat-checkbox>
                                    </div>
                                </th>
                                <th>{{'First Name' | translate}}</th>
                                <th>{{'Last Name' | translate}}</th>
                                <th>{{'Email' | translate}}</th>
                                <th>{{'Environment' | translate}}</th>
                                <th>{{'Role' | translate}}</th>
                                <th *ngIf="!selectProfileFlg">{{'Groups' | translate}}</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody class="loader-flex-center" *ngIf="userSpinner">
                            <div class="loader quantum-spinner m-auto"></div>
                        </tbody>
                        <tbody *ngIf="userList && userList.length <= 0 && !userSpinner">
                            <tr>
                                <td colspan="5" class="text-center">{{'No Data Found' | translate}}.</td>
                            </tr>
                        </tbody>
                        <tbody style="max-height: calc(95vh - 200px);">
                            <tr *ngFor="let singleUser of userList; let i = index">
                                <td class="t-col-normal">
                                    <div class="filter-box-checkbox-container">
                                        <mat-checkbox [(ngModel)]="singleUser.checked" (ngModelChange)="selectCheck(singleUser.checked, singleUser.userId);"></mat-checkbox>
                                    </div>
                                </td>
                                <td>{{singleUser.firstName}}</td>
                                <td>{{singleUser.lastName}}</td>
                                <td>{{singleUser.email}}</td>
                                <td *ngIf="singleUser.prod && singleUser.preProd">Preprod / Prod</td>
                                <td *ngIf="singleUser.prod && !singleUser.preProd">Prod</td>
                                <td *ngIf="!singleUser.prod && singleUser.preProd">Preprod</td>
                                <td *ngIf="!singleUser.prod && !singleUser.preProd">NA</td>
                                <td>
                                    <span *ngIf="singleUser.roleId && singleUser.roleId.length === 0">NA</span>
                                    <span *ngIf="singleUser.roleId && singleUser.roleId.length > 0">
                                        <span *ngFor="let role of singleUser.roleId; let i = index;">
                                            {{role.roleName ? role.roleName : ''}}<span *ngIf="singleUser.roleId.length !== i+1">, </span>
                                        </span>
                                    </span>
                                </td>
                                <td *ngIf="!selectProfileFlg">
                                    <span *ngIf="!singleUser.groups || (singleUser.groups && singleUser.groups.length === 0)">NA</span>
                                    <span *ngIf="singleUser.groups && singleUser.groups.length > 0">
                                        <span *ngFor="let group of singleUser.groups; let i = index;">
                                            {{group.groupName ? group.groupName : ''}}<span *ngIf="singleUser.groups.length !== i+1">, </span>
                                        </span>
                                    </span>
                                </td>
                                <td class="c-color cursor-p" (click)="forgrtPassword(singleUser.email)">{{'Change Password' | translate}}</td>
                                <td class="profile-manage-icon-row">
                                    <i class="fa fa-pencil-square-o t-color cursor-p" aria-hidden="true" (click)="editOn = true;getUser(i);btnSpinner=false;" *ngIf="user.data.email !== singleUser.email"></i>
                                    <i class="fa fa-trash t-color cursor-p" data-toggle="modal" data-target="#deletUserModal" aria-hidden="true" (click)="fname = singleUser.firstName; lname = singleUser.lastName; index = i;btnSpinner=false;" *ngIf="user.data.email !== singleUser.email"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createEditUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light align-items-center">
                <h5 class="modal-title password-reset-parent" id="exampleModalLabel">{{editOn ? ('Edit User'| translate) : ('Create New User' | translate)}}</h5>
                <p class="t-font t-color password-reset-class cursor-p mb-0 mr-4" *ngIf="editOn" tooltip="{{'Send Password Reset Link' | translate}}" placement="bottom"  (click)="forgrtPassword(currentUser.email)">{{'Password Reset' | translate}}</p>
                <button type="button" class="close t-color" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="editOn = false;submitted = false; resetForm(); createModal = false;">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4 device-flex-body">
                <form [formGroup]="addUserForm" (ngSubmit)="editOn ? editUser() : addUser()" *ngIf="addUserForm">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group text-left mb-4">
                                <label class="t-label" for="fName">{{'First Name' | translate}}</label>
                                <input type="text" class="form-control t-input" id="fName" formControlName="fname" aria-describedby="emailHelp">
                                <span class="help-block ng-invalid text-danger" *ngIf="(!f.fname.valid && !f.fname.pristine) || (f.fname.errors && submitted)">
                                    <span class="small" *ngIf="f.fname.errors.required">
                                        {{'First name is required' | translate}}!
                                    </span>
                                </span>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group text-left mb-4">
                                <label class="t-label" for="lName">{{'Last Name' | translate}}</label>
                                <input type="text" class="form-control t-input" id="lname" formControlName="lname" aria-describedby="emailHelp">
                                <span class="help-block ng-invalid text-danger" *ngIf="(!f.lname.valid && !f.lname.pristine) || (f.lname.errors && submitted)">
                                    <span class="small" *ngIf="f.lname.errors.required">
                                        {{'Last name is required' | translate}}!
                                    </span>
                                </span>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group text-left mb-4">
                                <label class="t-label" for="email">{{'Email Address' | translate}}</label>
                                <input type="email" class="form-control t-input" id="email" formControlName="email" aria-describedby="emailHelp">
                                <span class="help-block ng-invalid text-danger" *ngIf="(!f.email.valid && !f.email.pristine) || (f.email.errors && submitted)">
                                    <span class="small" *ngIf="f.email.errors.required">
                                        {{'Email is required' | translate}}!
                                    </span>
                                </span>
                                <span class="help-block ng-invalid text-danger" *ngIf="f.email.errors && submitted">
                                    <span class="small" *ngIf="f.email.errors.email">
                                        {{'Enter valid email' | translate}}!
                                    </span>
                                </span>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group text-left mb-4">
                                <label class="t-label" for="phoneNo">{{'Mobile Number' | translate}}</label>
                                <ngx-intl-tel-input [cssClass]="'custom'" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                    [maxLength]="10" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true" [separateDialCode]="true" name="phoneNo" formControlName="phoneNo" class="form-control t-input" id="phoneNo" [searchCountryPlaceholder]="'Search Country' | translate">
                                </ngx-intl-tel-input>
                                <span class="help-block ng-invalid text-danger" *ngIf="((!f.phoneNo.valid && !f.phoneNo.pristine) || (f.phoneNo.errors)) && submitted">
                                    <span class="small" *ngIf="f.phoneNo.errors.required">
                                        {{ 'Phone number is required' | translate}}!
                                    </span>
                                    <span class="small" *ngIf="!f.phoneNo.errors.required && !f.phoneNo.errors.validatePhoneNumber.valid">
                                        {{ 'Please enter valid Phone number' | translate}}!
                                    </span>
                                </span>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group text-left">
                                <mat-form-field appearance="outline" style="width: 100%; position: relative; z-index: 1000;">
                                    <mat-label>{{"Select Language" | translate}}</mat-label>
                                    <mat-select id="customerPlan" class="positioning" formControlName="language">
                                        <mat-option *ngFor="let lang of languageList" [value]="lang.key">{{lang.name | titlecase}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-select [(ngModel)]="sortBy">
                                <mat-option *ngFor="let opt of sortByOption; let i = index;" [value]="opt.value" (click)="selectSortBy(i);getProfiles();">{{opt.name}}</mat-option>
                            </mat-select>
                        </mat-form-field> -->

                        <!-- <div class="col-md-12">
                            <div class="form-group text-left mb-4" style="position: relative;">
                                <mat-form-field appearance="outline" style="width: 100%; position: relative; z-index: 1000;">
                                    <mat-label>{{'Role' | translate}}</mat-label>
                                    <mat-select id="userRole" class="positioning" formControlName="chooseRole">
                                        <mat-option *ngFor="let role of roleList" [value]="role.roleId">{{role.roleName}}</mat-option>
                                    </mat-select>
                                    <mat-hint>
                                        <span class="ng-invalid text-danger" *ngIf="(!f.chooseRole.valid && !f.chooseRole.pristine) || (f.chooseRole.errors && submitted)">
                                            <span *ngIf="f.chooseRole.errors.required">
                                                {{'Role is required' | translate}}!
                                            </span>
                                        </span>
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                        </div> -->

                        <div class="col-md-12 mb-3">
                            <div class="filter-box-checkbox-container">
                                <label class="t-label mr-4" for="exampleInputEmail1">{{'Environment' | translate}}:</label>
                                <mat-checkbox class="mr-4" formControlName="preProd" (ngModelChange)="envError= false">Preprod</mat-checkbox>
                                <mat-checkbox formControlName="prod" (ngModelChange)="envError= false">Prod</mat-checkbox>
                                <!-- <p class="small text-danger mt-2 mb-0" *ngIf="envError">{{envError | translate}}!</p> -->
                            </div>
                        </div>

                        <div class="col-md-12 mb-3" *ngIf="show">
                            <label class="t-label pull-left mr-2" for="profile-type-select">{{'Select Roles' | translate}}: </label>
                            <ng-multiselect-dropdown id="user-role" placeholder="{{'Search roles here' | translate}}..." [settings]="roleSettings" [data]="roleList" [(ngModel)]="selectedRoles" [ngModelOptions]="{standalone: true}" (click)="createModal=false;" (onDropDownClose)="selectRole();" (ngModelChange)="selectRoles();selectRole();">
                            </ng-multiselect-dropdown>
                            <p class="small text-danger mt-2 mb-0" *ngIf="roleErr">{{roleErr | translate}}</p>
                        </div>

                        <div class="col-md-12 mb-3" *ngIf="show && selectProfileFlg && selectedRoles && findRoleIndex()">
                            <label class="t-label pull-left mr-2" for="profile-type-select">{{'Select Profile Types' | translate}}: </label>
                            <ng-multiselect-dropdown id="profile-type-select" placeholder="{{'Search profile type here' | translate}}..." [settings]="profileDropdownSettings" [data]="profileTypeListWithGroupQR" [(ngModel)]="selectedProfileTypes" [ngModelOptions]="{standalone: true}" >
                            </ng-multiselect-dropdown>
                        </div>

                        <div class="col-md-12" *ngIf="!selectProfileFlg && show">
                            <label class="t-label pull-left mr-2" for="profile-type-select">{{'Select Group' | translate}}: </label>
                            <ng-multiselect-dropdown id="profile-type-select" placeholder="{{'Search group' | translate}}here..." [settings]="groupDropdownSettings" [data]="groupList" [(ngModel)]="selectedGroups" [ngModelOptions]="{standalone: true}">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <button class="btn t-bgcolor btn-block my-3 outline" *ngIf="!editOn && !btnSpinner"> {{'Submit' | translate}}</button>
                    <button class="btn t-bgcolor btn-block my-3 outline" *ngIf="editOn"> {{'Edit' | translate}}</button>
                    <div class="btn-spinner" *ngIf="!editOn && btnSpinner">
                        <div class="loader quantum-spinner m-auto"></div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createBulkUsers" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{'Add Bulk Users' | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="validJson=[];invalidJson=[];lineNos=[];error=false;fileName='';fileToUpload='';successArr=[];failArr=[];">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-3 device-flex-body">
                <div class="row align-items-center justify-content-center">
                    <div class="col-md-5 mb-4">
                        <a href="assets/csv/sample-user.csv" class="btn t-font-medium btn-bg-color outline">
                            <i class="fa fa-download mr-2"></i> {{'Download CSV' | translate}}
                        </a>
                    </div>
                    <div class="col-lg-6 mb-4">{{"Download template and update user's info accordingly before uploading" | translate}}</div>
                    <div class="col-md-5 mb-4">
                        <button class="btn c-btn my-3 d-block t-font-medium bulk-download-btn btn-bg-color outline" (click)="uploadProfilePic()">
                            <i class="fa fa-upload mr-2" aria-hidden="true"></i> {{'Upload CSV' | translate}}
                            <input id="upload-user-csv" type="file" style="display: none" accept=".csv" (change)="onFileChange($event)">
                        </button>
                    </div>
                    <div class="col-lg-6 mb-4">{{fileName}}</div>
                    <div class="col-lg-12">
                        <button class="btn t-bgcolor btn-block my-3 outline" [disabled]="enableSuccess" (click)="submitBulkUsers()" *ngIf="!btnSpinner">{{'Submit' | translate}}</button>
                        <div class="btn-spinner" *ngIf="btnSpinner">
                            <div class="loader quantum-spinner m-auto"></div>
                        </div>
                        <div class="text-center text-danger small" *ngIf="error">{{'Invalid CSV format, Error at line no' | translate}}:
                            <span *ngFor="let item of [lineNos]; let i=index;">{{ (i+1) === lineNos.length ? item : (item + ' ,')}} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="userConfirmationModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{'Update User Confirmation' | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" id="closeDeleteCustomer" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center p-4">
                <!-- <img src="assets/images/icons/delete.png" style="width: 50px; margin-bottom: 30px" alt=""> -->
                <p *ngIf="editOn">{{'Are you sure you want to save changes' | translate}}?</p>
                <button class="btn t-bgcolor btn-block my-3 outline" *ngIf="!btnSpinner" (click)="confirmEditUser()"> {{'Submit' | translate}}</button>
                <div class="btn-spinner" *ngIf="btnSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deletUserModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{'Delete Confirmation' | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" id="closeDeleteCustomer" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body text-center p-4">
                <img src="assets/images/icons/delete.png" style="width: 50px; margin-bottom: 30px" alt="">
                <p>{{'Are you sure you want to delete' | translate}} '{{fname}} {{lname}}'?</p>
                <button class="btn t-bgcolor btn-block my-3 outline" *ngIf="!btnSpinner" (click)="deleteUser()"> {{'Delete Now' | translate}}</button>
                <div class="btn-spinner" *ngIf="btnSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deletBulkUserModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{'Delete Selected Users' | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" id="closeDeleteCustomer" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body text-center p-4">
                <img src="assets/images/icons/delete.png" style="width: 50px; margin-bottom: 30px" alt="">
                <div *ngIf="selectedUserList.length === 1">
                    <p >{{selectedUserList.length}} {{'user is selected' | translate}}.</p>
                    <p>{{'Are you sure you want to delete selected user' | translate}}?</p>
                </div>
                <div *ngIf="selectedUserList.length > 1">
                    <p>{{selectedUserList.length}} {{'users are selected' | translate}}.</p>
                    <p>{{'Are you sure you want to delete selected users' | translate}}?</p>
                </div>
                <button class="btn t-bgcolor btn-block my-3 outline" *ngIf="!bulkDeleteBtnSpinner" (click)="deleteBulkUser()"> {{'Yes, Delete' | translate}}</button>
                <div class="btn-spinner" *ngIf="bulkDeleteBtnSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createBulkUserSuccessModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content t-modal">
            <button type="button" class="close t-color text-right mt-3 mr-3" data-dismiss="modal" id="closeDeleteCustomer" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body text-center p-4">
                <img src="assets/images/icons/checked.png" style="width: 50px; margin-bottom: 30px" alt="">
                <p>{{'Successfully added users' | translate}}: {{ successArr.length }}</p>
                <p class="mb-4">{{'Failed to add users' | translate}}: {{ failArr.length }}</p>
                <p class="t-font-light" style="font-size: 14px;">{{'Please check response file for details' | translate}}.</p>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="assignBulkUsers" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{'Update Bulk Users' | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="selectedProfileTypes=[];selectedGroups=[];">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4 device-flex-body">
                <div class="row">
                    <div class="col-md-12 mb-3" *ngIf="show">
                        <label class="t-label pull-left mr-2" for="profile-type-select">{{'Select Roles' | translate}}: </label>
                        <ng-multiselect-dropdown id="update-bulk-user-role" placeholder="{{'Search roles here' | translate}}..." [settings]="roleSettings" [data]="roleList" [(ngModel)]="selectedRoles" [ngModelOptions]="{standalone: true}" (onDropDownClose)="selectRole();" (ngModelChange)="selectRoles();selectUpdateRole();">
                        </ng-multiselect-dropdown>
                    </div>

                    <div class="col-md-12" *ngIf="show && !user.data.currentCustomer.isParent">
                        <label class="t-label pull-left mr-2" for="profile-type-select">{{'Select Group' | translate}}: </label>
                        <ng-multiselect-dropdown  id="profile-type-select" placeholder="{{'Search group here' | translate}}..." [settings]="groupDropdownSettings" [data]="groupList" [(ngModel)]="selectedGroups" [ngModelOptions]="{standalone: true}">
                        </ng-multiselect-dropdown>
                    </div>

                    <div class="col-md-12" *ngIf="show && user.data.currentCustomer.isParent">
                        <label class="t-label pull-left mr-2" for="profile-type-select">{{'Select Profile' | translate}}: </label>
                        <ng-multiselect-dropdown id="profile-type-select" placeholder="{{'Search profile type here' | translate}}" [settings]="profileDropdownSettings" [data]="profileList" [(ngModel)]="selectedProfiles" [ngModelOptions]="{standalone: true}" ></ng-multiselect-dropdown>
                    </div>
                </div>
                <button class="btn t-bgcolor btn-block my-3 outline" *ngIf="!btnSpinner" (click)="assignUsers();" [disabled]="!((selectedGroups && selectedGroups.length > 0) || (selectedRoles && selectedRoles.length > 0))">{{'Submit' | translate}}</button>
                <div class="btn-spinner" *ngIf="btnSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
            </div>
        </div>
    </div>
</div>
