<div class="profile-container">
	<!-- <div class="profile-data">
		<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 w-100">
			<ng-container matColumnDef="name">
			  <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
			  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
			</ng-container>
			
			<ng-container matColumnDef="position">
			  <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Profiles </th>
			  <td mat-cell *matCellDef="let element"> {{element.totalProfiles}} </td>
			</ng-container>
		  
			<ng-container matColumnDef="weight">
			  <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Download </th>
			  <td mat-cell *matCellDef="let element"> {{element.totalDownload}} </td>
			</ng-container>
		  
			<ng-container matColumnDef="symbol">
			  <th mat-header-cell *matHeaderCellDef mat-sort-header> Available </th>
			  <td mat-cell *matCellDef="let element"> {{element.available}} </td>
			</ng-container>
		  
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</div> -->


	<div class="face-container-inner-bg mb-2" *ngIf="features && features[getFeatureIndex('db_inventory')].view" style="height: 100vh;">
		<div class="border-bottom-light d-flex">
			<div class="p-3 title-container">
				<h4 class="mb-0">{{"Profile Inventory" | translate}}</h4>
			</div>
			<div class="ml-auto d-flex align-items-center mr-2">
				<button class="mr-2 btn t-bgcolor py-1 outline" *ngIf="whiteLabelEnable" tooltip="Sync latest provisioned profileTypes" placement="bottom" (click)="syncProfileType()"> <i class="fa fa-refresh mr-2" aria-hidden="true"></i> {{'Sync Profile Types' | translate}}</button>
				<div class="dropdown field-selection ">
					<button class="btn dropdown-toggle t-font-light field-types-container" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="mr-3 sg-font-regular">{{ "Field Selection" | translate}}</span></button>
					<div class="dropdown-menu" aria-labelledby="dropdownMenuButton" (click)="showList($event)">
						<a class="dropdown-item pl-2 sg-font-regular" *ngFor="let item of fieldList" (click)="updateTableColums(item)" [ngClass]="{'t-color': !item.selected, 'text-white': item.selected}">
							<i class="fa cursor-p mr-3" [ngClass]="{'fa-plus t-color': !item.selected, 'fa-check text-white': item.selected}" aria-hidden="true"></i>{{ item.name | translate }}
						</a>
					</div>
				</div>
				<div *ngIf="manualRefresh && isDashboardUser">
					<i class="fa fa-refresh mr-2 ml-4 cursor-p" style="font-size: 1vw;" (click)="refresh()"></i>
				</div>
			</div>
		</div>

		<div class="inventory-info-tables" style="height: 100vh;">
			<div class="table-lg-1">
				<div class="t-grid-table-new inventory-table">
					<div class="t-grid-table-wrapper t-head-bg" [ngStyle]="getStyles()">
						<div class="t-box a overflow-auto">{{'Profile Type' | translate}}</div>
						<div class="t-box c overflow-auto">{{'ICCID' | translate}}</div>
						<div class="t-box b overflow-auto">{{'Available' | translate}}</div>
						<div class="t-box c overflow-auto">{{'Released' | translate}}</div>
						<div class="t-box a overflow-auto">{{'Downloaded' | translate}}</div>
						<div class="t-box b overflow-auto">{{'Installed' | translate}}</div>
						<div class="t-box a overflow-auto">{{'Deleted' | translate}}</div>
						<div class="t-box c overflow-auto" *ngIf="activeTableColumns.includes('Allocated')">{{'Allocated' | translate}}</div>
						<div class="t-box a overflow-auto" *ngIf="activeTableColumns.includes('Linked')">{{'Linked' | translate}}</div>
						<div class="t-box b overflow-auto" *ngIf="activeTableColumns.includes('Confirmed')">{{'Confirmed' | translate}}</div>
						<div class="t-box c overflow-auto" *ngIf="activeTableColumns.includes('Enabled')">{{'Enabled' | translate}}</div>
						<div class="t-box b overflow-auto" *ngIf="activeTableColumns.includes('Error')">{{'Error' | translate}}</div>
						<div class="t-box b overflow-auto" *ngIf="activeTableColumns.includes('Reusable')">{{'Reusable' | translate}}</div>
						<div class="t-box c overflow-auto" *ngIf="activeTableColumns.includes('Unavailable')">{{'Unavailable' | translate}}</div>
						<div class="t-box c overflow-auto" *ngIf="activeTableColumns.includes('Disabled')">{{'Disabled' | translate}}</div>
						<div class="t-box c d-flex inner-main-profile">
							<p class="t-color mb-0">{{"Notification Settings" | translate}}</p>
							<div class="c-color c-info py-0 ml-2">
								<i class="c-color c-info py-0 fa fa-info icon-count-class" style="cursor: pointer; font-size: 10px;" data-toggle="modal" data-target="#profileNotificationModal"></i>
							</div>
						</div>
					</div>
					<div class="loader-flex-center" *ngIf="profileSpinner">
						<div class="loader quantum-spinner m-auto"></div>
					</div>
					<div *ngIf="profileTypes && profileTypes.length <= 0 && !profileSpinner">
						<div class="text-center mt-2 mb-2">
							<div class="text-center">{{'No Profile Type Found' | translate}}.</div>
						</div>
					</div>
					<div class="t-body-overflow" *ngIf="!profileSpinner && profileTypes && profileTypes.length > 0" [style.height]="calcHeightInventory()">
						<div class="t-grid-table-wrapper t-body-bg" *ngFor="let profileType of profileTypes" [ngStyle]="getStyles()">
							<div class="t-box d t-font-light overflow-auto cursor-p" (click)="navigateToManageProfile(profileType, 'profile')" title="{{profileType.profileType}}">{{profileType.profileType | limitTo: 21}}</div>
							<div class="t-box d t-font-light overflow-auto">
								{{profileType.total && profileType.total ? profileType.total : 0}}
							</div>
							<div class="t-box d t-font-light overflow-auto">{{profileType.AVAILABLE && profileType.AVAILABLE ? profileType.AVAILABLE : 0}}</div>
							<div class="t-box d t-font-light overflow-auto">{{profileType.RELEASED && profileType.RELEASED ? profileType.RELEASED : 0}}</div>
							<div class="t-box d t-font-light overflow-auto">{{profileType.DOWNLOADED && profileType.DOWNLOADED ? profileType.DOWNLOADED : 0}}</div>
							<div class="t-box d t-font-light overflow-auto">{{profileType.INSTALLED && profileType.INSTALLED ? profileType.INSTALLED : 0}}</div>
							<div class="t-box d t-font-light overflow-auto">{{profileType.DELETED && profileType.DELETED ? profileType.DELETED : 0}}</div>
							<div class="t-box d t-font-light overflow-auto" *ngIf="activeTableColumns.includes('Allocated')">{{profileType.ALLOCATED && profileType.ALLOCATED ? profileType.ALLOCATED : 0}}</div>
							<div class="t-box d t-font-light overflow-auto" *ngIf="activeTableColumns.includes('Linked')">{{profileType.LINKED && profileType.LINKED ? profileType.LINKED : 0}}</div>
							<div class="t-box d t-font-light overflow-auto" *ngIf="activeTableColumns.includes('Confirmed')">{{profileType.CONFIRMED && profileType.CONFIRMED ? profileType.CONFIRMED : 0}}</div>
							<div class="t-box d t-font-light overflow-auto" *ngIf="activeTableColumns.includes('Enabled')">{{profileType.ENABLED && profileType.ENABLED ? profileType.ENABLED : 0}}</div>
							<div class="t-box d t-font-light overflow-auto" *ngIf="activeTableColumns.includes('Error')">{{profileType.ERROR && profileType.ERROR ? profileType.ERROR : 0}}</div>
							<div class="t-box d t-font-light overflow-auto" *ngIf="activeTableColumns.includes('Reusable')">{{profileType.REUSABLE && profileType.REUSABLE ? profileType.REUSABLE : 0}}</div>
							<div class="t-box d t-font-light overflow-auto" *ngIf="activeTableColumns.includes('Unavailable')">{{profileType.UNAVAILABLE && profileType.UNAVAILABLE ? profileType.UNAVAILABLE : 0}}</div>
							<div class="t-box d t-font-light overflow-auto" *ngIf="activeTableColumns.includes('Disabled')">{{profileType.DISABLED && profileType.DISABLED ? profileType.DISABLED : 0}}</div>
							<div class="t-box d t-font-light">
								<i data-toggle="modal" data-target="#profileTypeSettings" aria-hidden="true" class="fa fa-cog cursor-p" (click)="sendProfileTypeData({profileType: profileType.profileType, totalCount: profileType.total})"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>



		<!-- <div class="table-responsive">
			<table class="table t-table table-scroll">
				<thead>
					<tr>
						<th class="col-4 font-weight-normal">{{'Name' | translate}}</th>
						<th class="font-weight-normal">{{'Total Profiles' | translate}}</th>
						<th class="font-weight-normal">{{'Total Download' | translate}}</th>
						<th class="font-weight-normal">{{'Available' | translate}}</th>
						<th class="font-weight-normal">
							<div class="d-flex inner-main-profile justify-content-end pr-0" style="padding-top: 2px;">
								<p class="t-color mb-0">{{"Notification Settings" | translate}}</p>
								<div class="c-color c-info py-0 ml-2">
									<i class="c-color c-info py-0 fa fa-info icon-count-class" style="cursor: pointer;" data-toggle="modal" data-target="#profileNotificationModal"></i>
								</div>
							</div>
						</th>
					</tr>
				</thead>
				<tbody class="loader-flex-center" *ngIf="profileSpinner">
					<div class="loader quantum-spinner m-auto"></div>
				</tbody>
				<tbody class="inventory-overflow" *ngIf="dashboardData && dashboardData.inventory && dashboardData.inventory.length <= 0 && !profileSpinner">
					<tr>
						<td colspan="5" class="text-center">{{'No Profile Type Found' | translate}}.</td>
					</tr>
				</tbody>
				<tbody class="inventory-overflow" *ngIf="!profileSpinner && dashboardData.inventory.length > 0">
					<tr class="cursor-p" *ngFor="let profileType of dashboardData.inventory; let i = index">
						<td class="col-4" (click)="navigateToManageProfile(profileType, 'profile')">{{profileType.profileType}}</td>
						<td (click)="navigateToManageProfile(profileType, 'profile')">{{profileType.total ? profileType.total : 0}}</td>
						<td (click)="navigateToManageProfile(profileType, 'downloaded')">{{profileType.DOWNLOADED ? profileType.DOWNLOADED : 0}}</td>
						<td (click)="navigateToManageProfile(profileType, 'available')">{{profileType.AVAILABLE ? profileType.AVAILABLE : 0}}</td>
						<td> 
							<i data-toggle="modal" data-target="#profileTypeSettings" aria-hidden="true" class="fa fa-cog" (click)="sendProfileTypeData({profileType: profileType.profileType, totalCount: profileType.total})"></i>
						</td>
					</tr>
				</tbody>
			</table>
		</div> -->
	</div>
</div>