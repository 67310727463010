<!-- <div class="pl-3 pt-2">
  <i class="t-color fa" [ngClass]="{'fa-lock': lockDrag, 'fa-unlock': !lockDrag}" aria-hidden="true" (click)="lockHandler()"></i>
</div> -->
<!-- <button (click)="openFullscreen();">Open Fullscreen</button> -->
<!-- <div class="d-flex">
  <a class="svg-editor-nav-link ml-auto mt-1 mr-2" (click)="openFullscreen();">
    <img src="assets/images/svg-editor/expand.svg" alt="" style="width: 25px;">
  </a>
</div> -->

<div class="dashboard-fixed-btn dashboard-fixed-zoom-btn" style="bottom: unset; z-index: 2;" id="takeAtourNav">
  <div class="cursor-p widget-hidden-btn" style="height: 10px;"></div>
  <button class="btn dashboard-bottom-btn d-flex align-items-center" (click)="openFullscreen()" *ngIf="isDashboardUser">
    <img class="arrow-icon" style="width: 20px;" src="assets/images/icons/full-screen.svg" alt="">
    <img class="dashboard-btn-icon text-white cursor-p" src="assets/images/icons/full-screen.svg" alt=""> 
    <span class="dashboard-bottom-text">{{'Full Screen' | translate}}</span>
  </button> 
</div>

<!-- <button (click)="closeFullscreen();">Close Fullscreen</button> -->

<div id="dashboard-grid">
  <gridster
  [options]="options"
  (drop)="onDrop($event)"
  style="background-color: transparent; min-height: 90vh; max-height: 90vh;" class="dynamic-dashboard-container">
  <ng-container *ngFor="let item of dashboardArray" style="overflow: hidden;">
    <gridster-item [item]="item" class="dashboard-widget grid-drag-element" id="{{item.name}}">
      <!-- [ngClass]="{'overflow-auto': item.name == 'Profile Type' || item.name == 'Device Analytics'}" -->
      <!-- <mat-toolbar>
        <mat-toolbar-row class="dashboard-widget-toolbar-row custom-heading">
           {{item.name}} 
          <div>
            <button mat-button class="dashboard-widget-toolbar-button">
              <mat-icon class="drag-handler">open_with</mat-icon>
            </button>
          </div>
        </mat-toolbar-row>
      </mat-toolbar> -->
      <ndc-dynamic  class="gridster-ignore-drag" [ndcDynamicComponent]=item.component [ndcDynamicOutputs]="outputs"></ndc-dynamic>
    </gridster-item>
  </ng-container>
</gridster>

<!-- Modal -->
<div class="modal fade" id="eSimOndeviceDownloadModal" tabindex="-1" role="dialog" aria-labelledby="eSimOndeviceDownloadModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    
      <div class="modal-content t-modal">
        <div class="text-center mr-3">
          <button type="button" class="close t-color" data-dismiss="modal" id="closePrepareProfile" aria-label="Close">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
          <div class="modal-body p-0 pl-3 device-flex-body">
            <p>Number of eSIM profiles currently installed in devices only. For total utilization count, please see report section.</p>
          </div>
      </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="profileNotificationModal" tabindex="-1" role="dialog" aria-labelledby="eSimOndeviceDownloadModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    
      <div class="modal-content t-modal">
        <div class="text-center mt-2 mr-3">
          <button type="button" class="close t-color" data-dismiss="modal" id="closePrepareProfile" aria-label="Close">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
          <div class="modal-body p-2 pl-4 pr-4 device-flex-body">
            <p>Set reminder to send notification when the profile pool has reached certain limit. This is to ensure user is aware when profile balance is low.</p>
          </div>
      </div>
  </div>
</div>


<!-- modal setting icon -->
<div class="modal fade" id="profileTypeSettings" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 510px;">
      <div class="modal-content t-modal" >
          <div class="modal-header text-center border-bottom-light">
              <h5 class="modal-title" id="exampleModalLabel">{{profileTypeData.profileType}}</h5>
              <button type="button" class="close t-color outline" data-dismiss="modal" id="closePrepareProfile" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body device-flex-body">
            <div class="loader-flex-center" *ngIf="spinner">
              <div class="loader quantum-spinner m-auto"></div>
            </div>
            <div class="profile-settings-inner" *ngIf="!spinner">
              <div class="border-bt-ext-light">
                <div>
                  <p><b>Limits:</b></p>
                </div>

                <div class="filter-box-checkbox-container mb-1 d-flex w-100 flex-wrap">
                  <div class="d-flex align-items-start">
                    <div class="limits-names">
                      <p>Available</p>
                    </div>
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="availableCount">
                      <mat-radio-button class="example-radio-button mr-3" style="margin-bottom: 0px !important;" *ngFor="let count of counts" [value]="count" [disabled]="profileTypeData.totalCount <= count">
                          {{count | translate}}
                          <div class="d-flex" *ngIf="availableCount === 'Custom' && count === 'Custom'">
                            <input class="t-input ml-3" style="width: 45px;" type="text" [(ngModel)]="customeAvailableCount" (keyup)="availableErr=false;">
                          </div>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <p class="small text-danger mb-0" *ngIf="availableErr">{{"Limit should be less than Total Profile Count" | translate}}!</p>
                </div>

                <div class="filter-box-checkbox-container mb-1 d-flex w-100 flex-wrap">
                  <div class="d-flex align-items-start">
                    <div class="limits-names">
                      <p>Released</p>
                    </div>
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="releaseCount">
                      <mat-radio-button class="example-radio-button mr-3" style="margin-bottom: 0px !important;" *ngFor="let count of counts" [value]="count" [disabled]="profileTypeData.totalCount <= count">
                          {{count | translate}}
                          <div class="d-flex" *ngIf="releaseCount === 'Custom' && count === 'Custom'">
                            <input class="t-input ml-3" style="width: 45px;" type="text" [(ngModel)]="customeReleaseCount" (keyup)="releaseErr=false;">
                          </div>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <p class="small text-danger mb-0" *ngIf="releaseErr">{{"Limit should be less than Total Profile Count" | translate}}!</p>
                </div>
              </div>

              <div class="border-bt-ext-light pb-3">
                <div class="mt-3">
                  <p><b>{{'Notification' | translate}}:</b></p>
                  <div class="d-flex align-items-start">
                    <div class="limits-names">
                      <p class="mb-0">{{'Frequency' | translate}}: </p>
                    </div>
                    <select class="custom-selection-dropdown date-range-dropdown outline" style="width: 110px;" [(ngModel)]="frequency">
                      <option value="daily">{{'Daily' | translate}}</option>
                      <option value="weekly">{{'Weekly' | translate}}</option>
                      <option value="monthly">{{'Monthly' | translate}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div>
                <button _ngcontent-qcs-c222="" *ngIf="!btnSpinner" (click)="setProfileLimit()" class="btn device-flex-bodydevice-flex-body c-btn btn-block mt-4 profile-info-tabs">Save</button>
                <div class="btn-spinner mt-4" *ngIf="btnSpinner">
                  <div class="loader quantum-spinner m-auto"></div>
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>
</div>
</div>

<div class="dashboard-fixed-btn" id="takeAtourNav" [ngClass]="{'d-none': !lockDrag, 'd-flex': lockDrag}" *ngIf="enableManageWidget">
  <div class="cursor-p widget-hidden-btn"></div>
  <button class="btn dashboard-bottom-btn d-flex align-items-center" (click)="showSidebarHandler()">
    <img class="arrow-icon" style="width: 20px;" src="assets/images/icons/manage-dashboard.svg" alt="">
    <img class="dashboard-btn-icon text-white cursor-p" src="assets/images/icons/manage-dashboard.svg" alt=""> 
    <span class="dashboard-bottom-text">{{'Manage Widgets' | translate}}</span>
  </button> 
</div>


